import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import avanceImmediate from './bank.jpg'
import dessin from './dessin.jpg'
import Tableau from './tableau'

function Element(){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Comparatif Banques Profesionnelles</title>
      <meta name="description" content="Comparatif des banques professionnels : Comment bien choisir sa banque, quelles obligations rencontrent les professionnels, quelles différences entre banque en ligne et traditionnelle ?"/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Les Banques professionnelles
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 1er octobre 2024
      </div>
      

      <img className={classes.image} src={avanceImmediate} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'-20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, width:'100%',}}>
        Sommaire
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span><a href='#obligation'>1. Quand un compte bancaire professionnel est obligatoire ?</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#choix'>2. Quel critère pour choisir sa banque ?</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#comparateur'>3. Notre comparateur</a>
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='introduction'>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Que nous lançons notre activité ou que nous souhaitons obtenir de meilleurs services,
        <br/> La banque qui nous accompagne est une pièce maitresse qui mérite d'être choisie avec attention.
        <br/>
        <br/>Dans cet article nous allons voir sous quelles conditions l'ouverture d'un compte professionnel est obligatoire, sur quels critères se baser lors du choix de son prestataire bancaire, et enfin un comparatif des banques en ligne et traditionnelles.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='obligation'>
        Quand est-il obligatoire d'ouvrir un compte bancaire professionel ?
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Cas des auto-entreprises et entreprises individuelles</span>
        <br/>
        <span style={{color:'white'}}> .......... </span>
        Pour les entrepreneurs qui créent leur entreprise individuelle (les auto-entreprises font partie de cette catégorie), l'ouverture d'un compte bancaire n'est pas obligatoire à la création car aucun capital n'est à réaliser.
        <br/>
        <br/>Vous avez dans ce cas le choix d'utiliser un compte particulier, que ce soit votre compte courant ou un compte particulier dédié à votre activité. 
        Vous pouvez bien sûr choisir d'ouvrir tout de même un compte professionnel.
        <br/>
        <br/><strong>Si votre chiffre d'affaires annuel dépasse le seuil des 10 000€ durant 2 années consécutives :</strong>
        <br/><span style={{color:'white'}}> .......... </span>
        Vous avez dans ce cas, l'obligation d'utiliser un compte bancaire dédié, qu'il s'agisse d'un compte bancaire particulier ou professionnel.
        Il doit uniquement percevoir les flux financiers liés à votre activité.
        <br/>
        <br/>

        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Cas des sociétés</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Lors de la création d'une société un capital doit être déposé, ce qui demande la création d'un compte bancaire professionnel.
        <br/>Votre banque vous fournira alors une attestation de dépôt des fonds, document nécessaire à l'immatriculation de votre structure.
        Une fois immatriculée, les fonds sont alors débloqués et pourront être utilisés librement pour votre activité.
        
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='choix'>
        Quels critères pour choisir la banque qui vous correspond ?
      </div>
      <img className={classes.image} src={dessin} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Banque en ligne ou traditionnelle ? Quel est le prix de l'abonnement ? Est-il possible de dépôser des chèques et des espèces ? Combien de cartes physiques et virtuelles sont incluses ? ...
        <br/>Autant d'interrogations qui pourraient nous laisser perplexe devant un secteur bancaire en pleine ébullition !
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Banque en ligne VS Banque en agence</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Depuis ces dix dernières années, les "Fintech" se sont développées avec leurs lots de nouveautés et prix attractifs.
        En addition de leurs offres en agences, les banques traditionnelles se sont elles aussi lancées (ou achetées) leur branche 100% en ligne :
        Propulse pour Crédit Agricole, Shine pour Société générale, HelloBank pour BNP Paribas, ... 
        <br/>
        <br/>Mais comment se retrouver dans cette multitude d'offres ??
        <br/>Il s'agira finalement plus de préférences personnelles et du budget que vous souhaitez y allouer.
        <br/>Les banques en ligne ont pour fer de lance leur application, proposant une multicité de services et une interface utilisateur inégalée.
        <br/>Quand les banques traditionnelles permettent l'accès à un conseiller que l'on peut rencontrer, un ensemble d'agences accessibles, ...
        <br/>
        <br/>Les banques en ligne filiales de banques traditionnelles, offrent un compromis intéressant, permettant de combiner expérience en ligne et l'accès aux guichets des banques traditionnelles pour le dépôt de chèques et d'espèces par exemple.
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Cartes bancaires physiques et virtuelles</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Avec des fonds bien plus importants que les particuliers, il est important de pouvoir protéger efficacement son compte bancaire, d'autant plus pour vos achats en ligne.
        <br/>La possibilité d'utiliser une carte virtuelle est dans ce cas une alternative très appréciable.
        <br/>
        <br/>Pour une structure avec plusieurs actionnaires / dirigeants, avoir la possibilité d'obtenir plusieurs cartes physiques est aussi un critère à considérer.
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Dépôt d'espèces et de chèques</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Dans le secteur du Service à la Personnes, les règlements par espèce ou chèque peuvent toujours être d'actualité.
        <br/>La plupart des banques peuvent accepter ces moyens de règlement, mais il est nécessaire de bien se renseigner sur les frais variables (par exemple certaines banques facturent 2€ par chèque encaissé). 
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Nombre de mouvements et devises</span>
        <br/><span style={{color:'white'}}> .......... </span>
        Le nombre de prélèvements et virements peut être limité par les banques professionnelles, pouvant vous facturer pour les mouvements dépassant ce seuil.
        <br/>Aussi dans le cas de transactions dans des devises étrangères, les banques peuvent appliquer des frais et taux de change variables.
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='comparateur'>
        Notre comparatif des banques professionnelles
      </div>
      <div className={classes.text} style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark, marginBottom:'40px'}}>Nous avons négocié pour vous 80€ auprès de notre partenaire bancaire Blank, n'hésitez pas à <a href='https://lb.affilae.com/r/?p=5faac37a7e67fa729bd876fb&af=388&lp=https%3A%2F%2Fwww.blank.app%2Flanding%2Fouverture-compte-pro-avance-immediate-services%3Futm_campaign%3DAvance%2520Imm%25C3%25A9diate%2520Services%26utm_source%3Daffilae%26utm_medium%3Daffiliation' rel='nofollow' target='_blank'>consulter leurs offres ici</a> !</div>
      <br/><br/>
      <Tableau/>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='conclusion'>
        Conclusion
      </div>
      <div className={classes.text}>
        <br/><span style={{color:'white'}}> .......... </span>
        Afin de réaliser le bon choix, il est nécessaire de bien connaitre ses besoins actuels et futurs :
        <br/>Est-ce que ma structure accepte ou acceptera les règlements par chèques? A-t-elle des besoins en crédits ou en services complémentaires ? ...
        <br/>
        <br/>Les tarifs des différentes offres doivent évidemment être pris en compte, mais être équipé et conseillé par la suite est tout aussi important.
        <br/>
        <br/>En espérant avoir pu vous aider à y voir un peu plus clair dans le large panel de banques existantes !
        <br/>Vous pourrez également retrouver notre comparatif dédié au choix de votre assurance de Responsabilité Civile Professionnelle sur <a href='articleAssurance'>cet article</a> !
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element
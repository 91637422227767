import { makeStyles } from "@mui/styles"

let color={
    //main1:'rgba(50,100,220)',
    main1:'#000080',
    //main1:'#00B4E6',
    //main1:'rgba(250,100,200)',
    //main2:'rgba(250,100,200)',
    main2:'#0CB1C4',
    //main3:'rgba(100,220,200)',
    main3:'rgba(250,100,200)',
    sub1:'rgba(200,200,250)',
    sub2:'rgba(250,200,250)',
    //sub3:'rgba(200,250,250)',
    sub3:'rgba(100,220,200)',
    
}

const style = makeStyles({
  button:{
    zIndex:'2', //permet de juxtaposer le commentary
    padding:'7px 15px 10px 15px',
    
    background:color.main2,
    cursor:'pointer',
    borderRadius:'20px',
    boxShadow:'2px 2px 8px 3px rgba(200,200,200)',
      
    color:'white',
    fontWeight:'bold',
    textDecoration:'none',
    textAlign:'center',
    transition:'1s ease-in-out',
    "&:hover": {
      boxShadow:'6px 6px 0px '+color.main3,
      filter:'brightness(1.1)',
      transition:'0.3s ease-in-out',
    },
  },
})
//style = style()

export default {color:color, style:style}
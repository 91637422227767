import axios from "axios"
import cookieComponent from './cookieComponent'

export default async function Element(props){
    //on doit lui envoyer {request:'', collection:'', body:{}}
    console.log(props)
    try{
        //paramètre requête
        let url='https://eu-west-2.aws.data.mongodb-api.com/app/publicandadmin-mlnjv/endpoint/guidesap'
        axios.defaults.headers.post['Authorization'] = JSON.stringify({
            collection:props.collection,
            request:props.request,
        })
        //ajout élément au body
        let body = props.body
        body.page = window.location.pathname
        try{
            body.cookie=await cookieComponent()
            body.user=body.cookie.user
            body.userDate=body.cookie.date
        }catch(e){body.cookie=false}
        //envoie de la requête
        let result = await axios.post(url, body)
            .then((r) => {return {boolean:true, data:r.data}})
            .catch((e)=>{return {boolean:false, data:'La requête a échouée, veuillez réessayer plus tard (' +e+')'}})
            console.log(result)
        return result
    }
    catch(e){
        console.log(e)
        return {boolean:false, data:e}
    }   
}

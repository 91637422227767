import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'
import ContactAIS from './contactAIS'


import avanceImmediate from './avanceImmediate2.jpg'
import fonctionnement from './fonctionnement.webp'
import ais from './ais.jpg'
import dessin from './dance.jpg'


function Element(props){
  const classes = ComponentArticle.useStyles()
  const [inscription, setInscription] = useState(0)
  
    return(<>
    <Helmet>
      <title>Avance Immédiate du Crédit d'Impôt</title>
      <meta name="description" content="Comment proposer le crédit d'impôt immédiat et être habilité API Tiers de prestation. Notre guide dédié à l'API de Urssaf et aux services à la personne."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Avance Immédiate du Crédit d'Impôt
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 25 août 2024
      </div>
      
      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Lancée le 14 juin 2022 par l'Urssaf et la Direction Générale des Finances Publiques (DGFIP),
        <br/>L'Avance Immédiate du Crédit d'Impôt, anciennement nommée Home+, permet aux particuliers de bénéficier instantanément des 50% de crédit d'impôt.
        <br/>Ils pourrons donc directement régler la moitié du montant de vos prestations ! Et n'aurons plus à avancer la moitié du montant des prestations perçues, jusqu'à leur prochaine déclaration fiscale (soit entre 6 et 18 mois).
      </div>
      <img className={classes.image} src={avanceImmediate} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.text}>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Sommaire</span>
        <br/><span style={{color:'white'}}> .......... </span><a href='#1'>Avantages</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#3'>Inscription des particuliers</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#4'>Déclaration de vos prestations</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#5'>Habilitation à l'API Tiers de prestation</a>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='1'>
        Avantages
      </div>
      <div className={classes.text}>
        Les avantages pour les particuliers sont évidents.
        <br/>Ils sont en fait d'autant plus importants pour les organismes de Service à la personne :
        <ul>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Augmentation du volume d'activité : </span> 
            <br/>Grâce à un coût perçu moindre pour vos actuels clients, ces derniers pourront augmenter d'autant la quantité de services perçus.
            <br/>Aussi, les foyers qui ne pouvaient avancer les 50% de crédit d'impôt, augmentent aujourd'hui la taille du marché du SAP.
          </li><br/>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Revalorisation des marges : </span> 
            <br/>Toujours grâce à la diminution du coût perçu, le secteur du SAP profite d'une augmentation générale des tarifs horaires (voir notre <a style={{textDecoration:'underline', cursor:'pointer'}} onClick={props.changePopup}>Baromètre</a>).
            <br/>Permettant de pallier en partie l'inflation aussi subie coté professionnel, et les difficultés chroniques de recrutement que connait notre secteur.
          </li><br/>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Diminution des impayés : </span> 
            <br/>Comme nous le verrons dans la section suivante, les prestations déclarées en Avance Immédiate sont prélevées directement au particulier par l'Urssaf.
            <br/>Réduisant d'autant les oublis, relances et impayés : Vous permettant de vous concentrer sur votre coeur d'activité.
          </li>
        </ul>
        <br/>
        En bref, l'Avance Immédiate du Crédit d'Impôt est l'<span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>argument massue</span> dans un environnement concurrentiel, vous permettant de gagner de nouveaux clients et de fidéliser les anciens.
        <br/>
        <br/>C'est pourquoi aujourd'hui (début 2024), c'est déjà plus de la <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>moitié des professionnels</span> qui proposent ce dispositif à leurs clients.
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='2'>
        Fonctionnement
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La mise en place de l'Avance Immédiate peut être décrite en 3 étapes simples :
        <br/>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>
          <br/>1. L'habilitation du professionnel à l'API Tiers de prestation ;
          <br/>2. L'inscription du particulier à l'Avance Immédiate ;
          <br/>3. La déclaration de vos prestations en Avance Immédiate.
        </span>
        <br/>
        <br/> 
        L'habilitation à l'API Tiers de prestation (pour simplifier : un autre nom pour l'avance immédiate) sera décrite étape par étape dans le chapitre suivant qui lui sera dédié.
        <br/>
      </div>
      <ContactAIS/>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='3'>
        Inscription des particuliers à l'Avance Immédiate
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src={inscription==1?'https://www.youtube.com/embed/l4w0gjzzv-w':'https://www.youtube.com/embed/4xd7QOgics0'}
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <div className={classes.tableau} style={{marginTop:'25px'}}>
        <div className={Component.style().button} style={inscription==1?{background:'rgba(200,200,200)'}:{}} onClick={()=>{setInscription(0)}}>Inscription par le particulier</div>
        <div className={Component.style().button} style={inscription==0?{background:'rgba(200,200,200)'}:{}} onClick={()=>{setInscription(1)}}>Inscription par le professionel</div>
      </div>
      
      <div className={classes.text}>
        <br/>
        <span style={{color:'white'}}> .......... </span>
        L'inscription du particulier à l'Avance Immédiate doit être réalisé sur une plateforme habilitée par l'Urssaf (comme notre partenaire Avance Immédiate Services, qui nous servira d'exemple par la suite).
        <br/>
        Cette démarche doit être réalisée par le professionnel ou le particulier sur cette même plateforme.
        <br/>
        <br/>
        L'Urssaf demande plusieurs informations afin de pouvoir retrouver le particulier dans la base des impôts (et ainsi demander à la DGFIP directement les 50% de crédit d'impôt), ainsi que le moyen de règlement du particulier.
        Les informations demandées seront ainsi :
        <ul>
          <li>Identité : Prénoms, nom d'usage et de naissance, date et lieu de naissance ;</li>
          <li>Contact : Adresse mail (valide), numéro de téléphone portable ;</li>
          <li>Adresse de résidence ;</li>
          <li>Coordonnées bancaires (le compte qui sera prélevé par l'Urssaf de la moitié du montant des prestations).</li>
        </ul>
        Une fois inscrit, le particulier recevra un mail de l'Urssaf où il devra valider la mise en relation avec le professionnel.
        <br/>Il s'agit de la seule action obligatoire de sa part.
        <br/>
        <br/>Les particuliers sans adresse mail ou téléphone portable pourront être inscrites avec ceux d'un proche. 
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='4'>
        Déclaration de vos prestations en Avance Immédiate
      </div> 
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/4D2qQzo8ZhI'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <div className={classes.text}>
        <br/>
        <span style={{color:'white'}}> .......... </span>
        Une fois vos prestations réalisées, vous pourrez déclarer vos prestations en Avance Immédiate, afin que vos clients bénéficient du dispositif fiscal.
        <br/>
        <br/>Comme pour l'inscription de vos clients, cette étape doit être réalisée sur un logiciel habilité à l'API Tiers de prestation.
        <br/>Pour ne pas changer les bonnes habitudes, vous trouverez ci-dessous comment la réaliser grâce à notre partenaire <a href='https://avance-immediate.fr/redirection/guidesap' rel='nofollow' target='_blank'>Avance Immédiate Services</a> :
        Cette solution vous permet également de générer vos factures, attestations fiscales en fin d'année, et de nombreuses fonctionnalités.
        <br/>
        <br/>La déclaration de vos prestations reprend donc les principales informations de vos prestations : date et numéro de facture, montant unitaire, quantité et type d'activité.
        <br/>
        <br/>Une fois votre prestation déclarée :
        <ul>
          <li>Votre client est notifié par l'Urssaf du montant et du nom de professionnel (par mail ou sms en fonction de ses préférences) ;</li>
          <li>Il a alors deux jours ouvrés pour valider ou contester cette demande de paiement. 
            <br/>La demande est automatiquement validée passé ce délai. 
            <br/>Votre client est alors directement prélevé par l'Urssaf de 50% du montant de vos prestations ;</li>
          <li>L'Urssaf rajoute les 50% de crédit d'impôt avant de vous renvoyer le montant total sur votre compte bancaire.</li>
        </ul> 
        Il n'y a donc plus de flux financier direct entre vous et votre client.
        <br/>Le prélèvement direct permet d'éviter les oublis, et diminue sensiblement les relances et impayés.
        <br/>
        <br/>Le client ne vous paie donc plus que la moitié du montant de vos prestations !
      </div>
      <img className={classes.image} src={fonctionnement} style={{width:'80vw', maxWidth:'700px', marginTop:'0px',}} alt="Description fonctionnement déclaration en Avance Immédiate du ccrédit d'impôt."/>
      <div className={classes.text}>
        <br/>Les conditions restent identiques au crédit d'impôt classique : 
        <br/>un particulier ne peut profiter des 50% de crédit d'impôt que sur les premiers 12 000€ de prestation annuelle. 
      </div>      


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='5'>
        Habilitation API Tiers de prestation
      </div> 
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Afin de proposer l'Avance Immédiate du Crédit d'Impôt à vos clients, il est nécessaire d'obtenir une seconde habilitation en plus de votre agrément/déclaration de Service à la Personne (aussi appellé Nova).
        <br/>
        <br/>Cette habilitation est celle de l' "API Tiers de prestation". 
        <br/>Il s'agit d'une démarche 100% en ligne à réaliser sur <a href='https://www.demarches-simplifiees.fr/commencer/api-tiers-de-prestations' rel='nofollow' target='_blank'>demarches-simplifiees.fr</a>.
        <br/>
        <br/>Notre partenaire Avance Immédiate Services peut aussi réaliser pour vous votre demande d'habilitation (et cela peut être réalisé gratuitement si vous prenez leur solution par la suite !).
      </div>
      <ContactAIS/>

      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/xqoNcm8QTWw'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <div className={classes.text}>
        <br/>Nous allons pouvoir voir l'ensemble des étapes de l'habilitation ci-dessous :
        <br/>
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Connexion</span>
        <br/>Vous devrez d'abord connecter, plusieurs possibilités vous seront alors offertes.
        <br/>Une fois connecté, vous pourrez indiquer le Siret de votre structure.
        <br/>
        <br/>Dans le cas où votre Siret n'est pas reconnu, il est possible que votre organisme ne soit pas public sur l'Insee.
        Il vous sera alors nécessaire de demander sa publication grâce au <a href='https://statut-diffusion-sirene.insee.fr/' rel='nofollow' target='_blank'>site dédié</a>.
        <br/>
        
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Informations liées à l'entreprise</span>
        <br/><strong>Nom du projet :</strong> Vous pourrez indiquer ici le nom donné à votre projet pour la mise en place de l'Avance Immédiate.
        <br/>Par exemple, vous pourrez simplement indiquer "Proposer l'Avance Immédiate du crédit d'Impôt".
        <br/>
        <br/><strong>Description du projet :</strong>
        <br/>Nous allons pouvoir ici rentrer dans le détail de notre activité.
        <br/>Par exemple : Mon organisme propose des services de nettoyage aux particuliers dans les environs de Rennes. Je souhaiterais permettre à mes clients particuliers de bénéficier de l'Avance Immédiate du Crédit d'Impôt.
        <br/>
        <br/><strong>Logiciel de facturation AICI :</strong>
        <br/>Afin de communiquer avec l'API Tiers de prestation et ainsi inscrire vos clients et déclarer vos prestations en Avance Immédiate, 
        il est nécessaire d'utiliser un logiciel habilité par l'Urssaf à l'API Tiers de prestation.
        <br/>En effet le professionnel doit être habilité ainsi que son logiciel de déclaration / facturation.
        <br/>
        <br/>Notre partenaire Avance Immédiate Services, solution de gestion dédiée aux Services à la Personne, est habilité par l'Urssaf à l'API Tiers de prestation.
        Il vous permet de proposer efficacement l'Avance Immédiate, générer vos factures, attestations fiscales, ... Comme nous avons pu le voir lors des précédentes vidéos.
        <br/>
        <br/>De plus il propose parmi les offres les plus abordables du marché (il s'agit du seul à offrir une version gratuite !), et vous accompagne à travers son support client dans votre habilitation et la mise en place de l'Avance Immédiate.
        <br/>
        <br/>
      </div>
      <img className={classes.image} src={ais} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',cursor:'pointer'}} alt="Logiciel dédiée SAP et habilité par l'Urssaf à l'API Tiers de prestation." onClick={()=>{window.open('https://avance-immediate.fr/redirection/guidesap', '_blank')}}/>
      <a className={Component.style().button} href='https://avance-immediate.fr/redirection/guidesap' rel='nofollow' target='_blank'>Découvrir Avance Immédiate Services</a>
      <div className={classes.text}>
        <br/>Vous pourrez alors sélectionner "J'ai recours à un éditeur de logiciel", et indiquer l'heureux élu.
        <br/>
        <br/><strong>Numéro SAP :</strong>
        <br/>Celui-ci pourra être retrouvé sur votre espace Nova, il s'agit des trois lettres "SAP" (comme Service À la Personne), suivi de votre SIREN (9 chiffres).
        <br/>
        <br/>Si vous n'êtes pas encore agrée/déclaré Service à la Personne, vous pourrez trouver notre article dédié <a href='/guide'>ici</a>
        <br/>
        <br/><strong>Maquette du projet :</strong>
        <br/>L'Urssaf souhaite vérifier comment les professionnels communiquent à propos de l'Avance Immédiate,
        <br/>Afin de s'assurer que les particuliers recoivent bien l'ensemble des informations, avec les bons termes.
        <br/>
        <br/>Pour cela l'Urssaf a créé un kit de communication complet (téléchargeable <a href='https://www.urssaf.fr/portail/files/live/sites/urssaf/files/documents/SAP-Kits-communication-Avance-immediate.zip' rel='nofollow' target='_blank'>ici</a>).
        Cette étape de l'habilitation peut être validée simplement en important les trois fichiers suivants :
        <ul>
          <li><a href='https://avance-immediate.fr/Documents/habilitationMailType.docx' rel='nofollow' target='_blank'>Un mail type</a>, qui devra être légèrement adapté afin de correspondre à votre structure (les trois champs surlignés en jaune) (si vous ne parvenez pas à lancer le téléchargement, veuillez ouvrir ce lien dans Edge, Google Chrome ou Safari );</li>
          <li><a href='https://avance-immediate.fr/Documents/habilitationInfographie.pdf' rel='nofollow' target='_blank'>Une infographie</a> présentant le fonctionnement des demandes de paiement ;</li>
          <li><a href='https://avance-immediate.fr/Documents/habilitationDepliant.pdf' rel='nofollow' target='_blank'>Un dépliant récapitulatif</a>.</li>
        </ul>
        <br/><strong>Date de mise en production prévue :</strong>
        <br/>Vous pouvez ici indiquer la date d'aujourd'hui.
        <br/>
        <br/><strong>Attestation de régularité fiscale :</strong>
        <br/>Dans le cas où vous êtes accompagné par un expert comptable, vous pouvez vous en rapprocher afin d'obtenir ce document.
        <br/>Sinon en fonction de votre statut, vous pourrez obtenir ce document sur les sites suivants :
        <ul>
          <li>Dans votre espace professionnel <a href='https://www.urssaf.fr/portail/portail/home/connectez-vous.html' rel='nofollow' target='_blank'>Urssaf</a>, onglet Documents / Attestations ;</li>
          <li>Dans votre espace professionnel <a href='https://www.impots.gouv.fr/accueil' rel='nofollow' target='_blank'>impots.gouv.fr</a>, onglet Consulter / Compte Fiscal / Attestations fiscales ;</li>
          <li>Si ce document n'est pas disponible dans ces espaces, vous pourrez vous rendre dans votre espace <a href='https://www.impots.gouv.fr/accueil' rel='nofollow' target='_blank'>impots.gouv.fr</a> (ou demander sa création si besoin), et demander le document dans l'onglet Messagerie sécurisée.</li>
        </ul>
        <br/><strong>Coordonnées bancaires - Compte de virement</strong>
        <br/>Il s'agit du compte qui recevra votre chiffre d'affaires en avance immédiate. C'est à dire les 50% du montant prélevés à votre client, majorés des 50% de crédit d'impôt.
        <br/>
        <br/><strong>Coordonnées bancaires - Compte de prélèvement</strong>
        <br/>Si l'Urssaf ne parvient pas à prélever un de vos clients, à cause de fonds insuffisants par exemple, l'Urssaf vous versera les 100% du montant de votre prestation, avant de vous reprélever les 50% qu'elle n'a pu obtenir auprès de votre client.
        <br/>Ce sera alors à votre client de vous verser directement les 50% manquant.
        <br/>
        <br/>Si vous souhaitez que le compte prélevé, soit différent de celui qui perçoit votre chiffre d'affaires, vous pouvez l'indiquer ici.
        Sinon vous pouvez laisser ce champs vide (comme l'immense majorité des professionnels).
        <br/>
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Information prestataires et contact</span>
        <br/><strong>Raison sociale du prestataire</strong>
        <br/>La raison sociale correspond au nom administratif de votre structure.
        <br/>Vous pourrez le vérifier en recherchant votre Siren sur le site <a href='https://www.pappers.fr/' rel='nofollow' target='_blank'>Pappers.fr</a>
        <br/>
        <br/><strong>Nom commercial</strong>
        <br/>Si votre structure n'a pas de nom commercial, vous pourrez indiquer à nouveau sa raison sociale.
        <br/>Vous pourrez ici aussi le vérifier sur le site Pappers.fr : le nom commercial apparait entre parenthèses.
        <br/>
        <br/><strong>Téléphone, mail et adresse postale</strong>
        <br/>Comme l'ensemble des informations indiquées dans la partie "2.Informations Prestataires", celles-ci seront visibles par vos clients dans leur espace particulier Urssaf.
        <br/>Veuillez à ce que les informations indiquées correspondent à vos contacts professionnels.
        <br/>
        <br/>

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Contacts</span>
        <br/>Quel que soit la taille de votre structure, vous pouvez indiquer ici la même personne pour l'ensemble des contacts.
        <br/>
        <br/>Veuillez vous assurer que vous avez bien accès aux contacts indiqués : C'est par mail que vous recevrez les demandes d'informations complémentaires, et surtout vos clés API Tiers de prestation une fois votre habilitation validée !
        <br/>
        <br/>Vous pouvez à présent accepter les CGU et déposer votre demande d'habilitation. 
        <br/>L'Urssaf va maintenant traiter votre dossier et reviendra vers vous par mail.
        <br/>
        <br/>Vous pourrez bientôt proposer l'Avance Immédiate du Crédit d'Impôt à vos clients !
        <br/>
      </div>
      <img className={classes.image} src={dessin} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',}} alt=" "/>
      
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>


    </div>
    </div>



    </>)
}

export default Element
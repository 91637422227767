import { useState, useEffect, useLayoutEffect } from "react"
import {clsx} from 'clsx'
import ComponentArticle from '../componentArticle'


let formeListe=[
    {
        title:'Auto ou Micro-entreprise (AE)',
        id:'ae',
        introduction:"La création d’une micro-entreprise est souvent favorisée pour des projets de petite envergure, comme une activité secondaire ou afin de tester le marché dans un premier temps.<br/><br/> Le chiffre d'affaires est alors limité à 77 700€ par an, pour une activité de service.",
        identite:"La micro-entreprise permet à l\'entrepreneur d\'exercer son activité sous son propre nom, sans créer une entité distincte. Cela signifie que l’entrepreneur et l’entreprise sont une unique et même personne.",
        creation:"Les procédures de création d\'une micro-entreprise sont simples. Une déclaration en ligne est généralement suffisante. Il n\’est pas nécessaire de rédiger des statuts ou d’injecter des fonds pour constituer le capital.",
        responsabilite:"Depuis 2022 votre responsabilité est limitée. Dans le cas de difficultés financières, les biens personnels de l’entrepreneur sont préservés, seuls les actifs nécessaires à l'exercice de votre activité professionnelle pourront être saisis.",
        remuneration:"En micro-entreprise vous serez rattaché au régime de la Sécurité sociale des indépendants. Vous êtes soumis au régime micro-social, les charges sociales peuvent être payées mensuellement ou trimestriellement.<br/> Dans le cadre d’une activité de SAP elles seront de 21,2%, majoré de 0,2% pour la contribution à la formation professionnelle, et 0,044% de frais consulaires. <br/> Les protections sociales étant assez faibles, il est possible de souscrire à des contrats de prévoyance et de retraite complémentaire.",
        regimeFiscal:"Le régime fiscal est celui du régime micro-fiscal.<br/> Votre chiffre d'affaires peut être assimilé à vos revenus, bénéficiant d'abattement en fonction de votre activité. <br/> Ou vous pouvez opter pour le versement forfaitaire libératoire, limitant à 1,7% votre taux d'imposition pour vos activités de service.",
    },
    {
        title:'Entreprise individuelle (EI)',
        id:'ei',
        introduction:"L\'entreprise individuelle est un statut juridique souvent choisie par les entrepreneurs pour sa simplicité de création et de gestion.",
        identite:"L\'entreprise individuelle permet à l\'entrepreneur d\'exercer son activité en nom propre, sans avoir à créer une entité distincte. <br/>Cela signifie que l’entrepreneur et l\’entreprise sont une unique et même personne.",
        creation:"Les procédures de création d\'une entreprise individuelle sont simples. Une déclaration en ligne est généralement suffisante. Il n’est pas nécessaire de rédiger des statuts ou d\’injecter des fonds pour constituer le capital.",
        responsabilite:"Depuis 2022 votre responsabilité est limitée. Dans le cas de difficultés financières, les biens personnels de l\’entrepreneur sont préservés, seuls les actifs nécessaires à l\'exercice de votre activité professionnelle pourront être saisis.",
        remuneration:"En entreprise individuelle vous serez rattaché au régime de la Sécurité sociale des indépendants. <br/>Vous disposez du statut social de travailleur non-salarié (TNS) : Les cotisations seront alors à hauteur de 45 % du bénéfice. <br/>Ces cotisations relativement faibles vous offrent en revanche peu de protection : Il vous sera alors possible de souscrire à des contrats de prévoyance et de retraite complémentaire.",
        regimeFiscal:"Le régime fiscal est par défaut celui de l’Impôt sur le Revenu.<br/> Vous êtes alors soumis à un régime réel d\'imposition (simplifié ou normal) sur le bénéfice imposable dégagé par votre activité.",
    },
    {
        title:'Entreprise unipersonnelle à Responsabilité Limitée (EURL)',
        id:'eurl',
        introduction:"L'EURL est fréquemment privilégiée par les entrepreneurs désirant profiter de la création d'une personne morale indépendante.",
        identite:"En EURL vous êtes l'unique décisionnaire. Il est possible de séparer les pouvoirs entre l'associé unique et le gérant.",
        creation:"La création d'une EURL est un peu plus complexe qu'une EI. Elle est composée de 4 étapes : <ul><li>La rédaction des statuts (avec peu de liberté comparée aux SARL ou SAS) ;</li><li> La déposition d’un capital social ;</li><li> La publication d’un avis de constitution de la structure dans un journal d’annonces légales ;</li><li> La demande pour votre immatriculation au registre du commerce et des sociétés.</li></ul>",
        responsabilite:"Votre responsabilité financière en EURL est strictement limitée à vos apports. <br/>En cas de difficultés financières, l'associé ne peut pas perdre plus que son apport initial.",
        remuneration:"L'associé sera rattaché au régime de la Sécurité sociale des indépendants. <br/> Vous disposez du statut social de travailleur non-salarié (TNS) : Les cotisations seront alors à hauteur de 45% du bénéfice. <br/>Ces cotisations relativement faibles proposent peu de protections : Il vous sera possible de souscrire à des contrats de prévoyance et de retraite complémentaire. <br/>Si vous n’êtes pas gérant majoritaire, vous êtes alors rattaché au régime général de la Sécurité sociale (en tant qu'Assimilé-Salarié et non plus TNS).",
        regimeFiscal:"Le régime fiscal est par default celui de l’Impôt sur le Revenu.<br/> Vous êtes alors soumis au régime réel d'imposition sur le bénéfice.",
    },
    {
        title:'Société par actions simplifiée unipersonnelle (SASU)',
        id:'sasu',
        introduction:"La SASU est privilégiée par les entrepreneurs désireux de conserver une totale autonomie tout en bénéficiant des avantages de la structure juridique d'une société par actions simplifiée.",
        identite:"Au sein d'une SASU, le créateur est à la fois président et actionnaire unique de sa société. <br/>Il vous est possible d’embaucher des salariés dans une SASU.",
        creation:"Les démarches administratives pour la création d'une SASU sont plus complexes. Elles se composent de 4 grandes étapes : <ul><li>La rédaction des statuts, qui dans le cas d’une SASU vous laisse une grande liberté ;</li><li>Le dépôt d’un capital social ; </li><li>La publication d'un avis de constitution de la structure dans un journal d'annonces légales ; </li><li>La demande pour votre immatriculation au registre du commerce et des sociétés.</li></ul>",
        responsabilite:"Votre responsabilité financière en SASU est strictement limitée à vos apports. <br/>En cas de difficultés financières, l'actionnaire unique ne peut perdre plus que son apport.",
        remuneration:"En tant que dirigeant d'une SASU, vous bénéficiez d'un statut assimilé-salarié, et êtes donc affilié au régime général de la Sécurité sociale. <br/>Pour les salaires que vous percevez, vous devrez régler des cotisations sociales à hauteur de 85% du montant versé. Ce régime vous offre une excellente couverture sociale.",
        regimeFiscal:"Le régime fiscal de base est celui de l’Impôt sur les Sociétés (IS). Les bénéfices de votre structure seront alors imposés à hauteur de 25% (et 15% pour la tranche sous 42 500 €). <br/>Vous pourrez alors vous verser des dividendes.<br/> La SASU permet aux entrepreneurs de lancer leur activité tout en bénéficiant de l'ARE, de percevoir leurs allocations chômage, sous réserve de l'absence de rémunération.",
    },
    {
        title:'Société à responsabilité limitée (SARL)',
        id:'sarl',
        introduction:"La SARL est fréquemment privilégiée par les entrepreneurs désirant lancer une activité à plusieurs actionnaires et profiter des avantages d'une structure à responsabilité limitée.",
        identite:"La SARL est une personne morale indépendante de ses actionnaires, ceux-ci peuvent être seul ou plusieurs (maximum 100). Il est possible de séparer les pouvoirs entre le président et le gérant.",
        creation:"Les démarches pour créer une SARL sont plus complexes qu'une EI ou EURL. Elles se composent de 4 grandes étapes : <ul><li>La rédaction des statuts (avec moins de liberté comparée aux SASU ou SAS) ;</li><li> Le dépôt d’un capital social ;</li><li> La publication d’un avis de constitution de la structure dans un journal d’annonces légales ;</li><li> La demande pour votre immatriculation au registre du commerce et des sociétés.</li></ul>",
        responsabilite:"La responsabilité financière des associés en SARL est strictement limitée à leurs apports. <br/>En cas de difficultés financières, seul le capital social de la société peut être perdu.",
        remuneration:"Seul le gérant majoritaire sera considéré comme Travailleur Non Salarié (TNS), et sera rattaché au régime de la Sécurité sociale des indépendants. Les cotisations seront à hauteur de 45 % du bénéfice. Les protections sociales étant faibles, il vous sera possible de souscrire à des contrats de prévoyance et de retraite complémentaire. <br/>Les gérants non majoritaires sont rattachés au régime général de la Sécurité sociale (assimilé salarié), les charges sociales sont dans ce cas à hauteur de 85% et propose une excellente couverture sociale.",
        regimeFiscal:"Le régime fiscal par defaut est celui de l’Impôt sur les Sociétés (IS).<br/> Les bénéfices seront alors imposés à hauteur de 25% (et 15% pour la tranche sous 42 500€). <br/>Vous avez également la possibilité de décider d'être assujetti à l'impôt sur le revenu pendant 5 ans, dans le cas d’une SARL de famille cette limite de temps disparait.",
    },
    {
        title:'Société par actions simplifiée (SAS)',
        id:'sas',
        introduction:"La SAS est la structure qui propose la plus grande flexibilité dans son fonctionnement, laissant aux associés le pouvoir de définir les règles selon leurs besoins.",
        identite:"Contrairement à la SASU, la SAS peut compter un nombre illimité d'associés. <br/> La SAS est une personne morale indépendante des associés.",
        creation:"La démarche pour créer une SAS est plus complexe et coûteuse (environ 500€). Elle se compose de 4 grandes étapes : <ul><li>La rédaction des statuts, permettant une grande liberté en SAS;</li><li> Le dépôt d’un capital social ; </li><li>La diffusion de l’avis de création de la structure dans un journal d'annonces légales;</li><li> Son immatriculation au registre du commerce et des sociétés.</li></ul>",
        responsabilite:"La responsabilité financière des associés en SAS est strictement limitée à leurs apports. En cas de difficultés financières, les associés ne peuvent perdre plus que leurs apports.",
        remuneration:"Les dirigeants de SAS bénéficient du statut assimilé-salarié, et sont affiliés au régime général de la Sécurité sociale. Les cotisations salariales sont à hauteur de 85% sur les salaires versés. Ce régime offre une excellente couverture sociale. Il est aussi possible de ne pas verser de salaire, si vous percevez l'ARE ou le chômage par exemple.",
        regimeFiscal:"Le régime fiscal par défaut est celui de l’Impôt sur les Sociétés (IS). <br/>Les bénéfices seront alors imposés à hauteur de 25 % (et 15% pour la tranche sous 42 500 €). La structure peut ainsi verser des dividendes à ses actionnaires. Elles seront soit taxées comme revenus, soit se verront appliquées le régime de Prélèvement Forfaitaire Unique PFU à 30% (aussi appellé 'flat tax'). <br/>Il est également possible d'être assujetti à l'impôt sur le revenu pendant 5 ans. ",
    },
]

function Component(){
    const classes = ComponentArticle.useStyles()
    
    useLayoutEffect(() => {
        //RAZ
        let tableauElement = document.getElementById('tableau')
        tableauElement.innerHTML = ''
        for(let column in formeListe){
            //ajout column
            let element = document.createElement('div')
            element.className=classes.papper
            element.innerHTML=formeListe[column].title
            element.addEventListener('mouseover', ()=>{changeForme(column)})
            element.id = formeListe[column].id
            tableauElement.appendChild(element)
        }
    })

    function changeForme(newValue){
        //on affiche description si ce n'est pas déjà le cas
        document.getElementById('description').style.height = ''
        document.getElementById('description').style.visibility = ''
        //change style
        let test = document.getElementsByClassName(classes.papper)//.style.background = activityTableau[activityGroup].color2
        for(let papper of test){if(!!papper.id){papper.style.background ='rgba(200,220,250)'}}
        document.getElementById(formeListe[newValue].id).style.background = ComponentArticle.color.column1Dark
        //write html
        for(let key of Object.keys(formeListe[newValue])){
            if(key!='id'){
                document.getElementById(key).innerHTML = formeListe[newValue][key]
            }
        }
    }
    
    return(<>
        
        <div style={{width:'100%',marginTop:'10px', fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Veuillez sélectionner une forme juridique :</div>
        
        <div className={classes.tableau} style={{marginTop:'10px',}} id ='tableau'/>

        <div style={{marginTop:'40px', width:'80%', color:'black', height:'0', visibility:'hidden'}} id='description'>
            
            <div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark, fontSize:'1.1em'}} id='title'/>
            
            <div id='introduction'/>
                
            <br/><br/><div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark}}>
                Identité
            </div>
            <div id='identite'/>
            
            <br/><div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark}}>
                Création
            </div>
            <div id='creation'/>
            
            <br/><div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark}}>
                Responsabilité
            </div>
            <div id='responsabilite'/>
            
            <br/><div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark}}>
                Rémunération
            </div>
            <div id='remuneration'/>
            
            <br/><div className={classes.subTitle} style={{color:ComponentArticle.color.column1Dark}}>
                Régime fiscal
            </div>
            <div id='regimeFiscal'/>

            
        </div>

    </>)
}

export default Component
import { useState, useEffect, useLayoutEffect } from "react"
import {clsx} from 'clsx'
import { makeStyles } from "@mui/styles"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import mongoWrite from '../../Component/mongoWrite'

import simplis from './simplis.png'
import hiscox from './hiscox.png'
import orus from './orus.png'
import axa from './axa2.png'
import abeille from './abeille.png'
import allianz from './allianz.png'

const useStyles = makeStyles({
    tableau:{
        
        display:'flex',
        flexDirection:'column',
    },
    line:{
        //width:'100%',
        //minHeight:'80px',
        padding:'0vw 1vw 0vw 1vw',

        display:'flex',
        flexDirection:'row',
        alignItems:'center',

        //color:'pink',
    },
    lineTitle:{
        background:ComponentArticle.color.column1Dark,
        borderRadius:'10px 10px 0px 0px',
        color:'white',
        fontWeight:'bold',
    },
    element:{
        width:'10vw', //pour que le nombre colonne * width >= 100%
        maxWidth:'140px', //pour que le nombre colonne * width >= 100%
        height:'7vw', //pour que le nombre colonne * width >= 100%
        minHeight:'60px',
        maxHeight:'100px', //pour que le nombre colonne * width >= 100%

        //textAlign:'center',
        //padding:'10px',
        
        display:'flex',
        justifyContent:'center',
        alignItems:'center',

        //border:'2px solid'
        "@media (max-width: 700px)": {width:'17vw',}, //should be egal to other
    },
    elementText:{
        width:'100%',
        //fontSize:'1vw', 
        textAlign:'center',
        //border:'2px solid pink',
        "@media (max-width: 1050px)": {
            fontSize: "0.8em",
        },
    },
    button:{
        padding:'0.4vw 0.4vw 0.4vw 0.4vw',
        //width:'80px',
        
        background:Component.color.main1,
        cursor:'pointer',
        borderRadius:'1vw',
        boxShadow:'2px 2px 8px 3px rgba(200,200,200)',
          
        color:'white',
        fontWeight:'bold',
        textDecoration:'none',
        transition:'1s ease-in-out',
        "&:hover": {
          boxShadow:'6px 6px 0px '+Component.color.main2,
          filter:'brightness(1.1)',
          transition:'0.3s ease-in-out',
        },

      },
})

function Element(){
    //const classes = ComponentArticle.useStyles()
    const classes = useStyles()

    let listLine=[
      {nom:'', description:'Description', prix:'Mensualité HT', rcProfessionnelle:'RC Professionnelle', rcExploitation:'RC Exploitation', rcLivraison:'RC Après livraison', rcJuridique:'Protection juridique', decouvrir:'Offres'},
      //{id:'simplis', nom:'Simplis', image:simplis, description:'Spécialisé Indépendant et AE', prix:'10€', rcProfessionnelle:'✔️', rcExploitation:'❌', rcLivraison:'❌', rcJuridique:'✔️', avis:'Non', decouvrir:'Découvrir', link:'https://www.simplis.fr/'},
      {id:'orus', nom:'Orus', image:orus, description:'Spécialisé TPE/PME', prix:'11,7€', rcProfessionnelle:'✔️', rcExploitation:'Option', rcLivraison:'✔️', rcJuridique:'✔️', avis:'Non', decouvrir:'10% de réduction', link:'https://www.orus.eu/partnerships/avance-immediate-services?utm_campaign=Affilae&utm_medium=partner&utm_source=Avance%20Imm%C3%A9diate%20Services#ae185'},
      {id:'hiscox', nom:'Hiscox', image:hiscox, description:'Assureur anglais en ligne', prix:'12,7€', rcProfessionnelle:'✔️', rcExploitation:'Option', rcLivraison:'✔️', rcJuridique:'✔️', avis:'Non', decouvrir:'Découvrir', link:'https://www.hiscox.fr/'},
      {id:'axa', nom:'Axa', image:axa, description:'Assureur français, 1er européen', prix:'14€', rcProfessionnelle:'✔️', rcExploitation:'❌', rcLivraison:'✔️', rcJuridique:'❌', avis:'Oui', decouvrir:'Découvrir', link:'https://www.axa.fr/pro/responsabilite-civile-professionnelle.html'},
      {id:'abeille', nom:'Abeille', image:abeille, description:'Fusion de Aésio et Macif', prix:'15€', rcProfessionnelle:'✔️', rcExploitation:'✔️', rcLivraison:'✔️', rcJuridique:'✔️', avis:'Non', decouvrir:'Découvrir', link:'https://www.abeille-assurances.fr/assurance-professionnelle/mon-activite/responsabilite-civile.html'},
      {id:'allianz', nom:'Allianz', image:allianz, description:'Assureur allemand, acteur mondial', prix:'devis', rcProfessionnelle:'✔️', rcExploitation:'✔️', rcLivraison:'✔️', rcJuridique:'❌', avis:'Non', decouvrir:'Découvrir', link:'https://www.allianz.fr/assurances-professionnels-entreprises/mon-activite/assurer-mes-responsabilites.html'},
    ]

    useLayoutEffect(() => {
        //RAZ
        let tableau = document.getElementById('tableau')
        tableau.innerHTML = ''
        //on cache certaine colonne si taille écran trop petite
        if(window.innerWidth<700){ for(let i in listLine){
            delete listLine[i].description
            delete listLine[i].rcProfessionnelle
            delete listLine[i].rcExploitation
        }}
        //tab
        for(let [l,line] of Object.entries(listLine)){
            //on créer une ligne
            let elementLine = document.createElement('div')
            if(l==0){elementLine.className=clsx(classes.line, classes.lineTitle)}
            else{elementLine.className=classes.line}
            if(l%2==0 && l>0){elementLine.style.background=ComponentArticle.color.column1Light}
            if(l==(listLine.length-1)){elementLine.style.borderRadius='0px 0px 10px 10px'}
            //on créer chaque case
            for(let column of Object.keys(listLine[0])){
                let element = document.createElement('div')
                element.className=classes.element
                //on créer le contenu de la case
                let elementIn = document.createElement('div')
                elementIn.className=classes.elementText
                elementIn.innerHTML=line[column]
                //case spéciale
                if(column=='nom' && !!line.image){
                    elementIn = document.createElement('img')
                    elementIn.className=classes.elementText//Image
                    elementIn.style.width='10vw'
                    elementIn.style.maxWidth='100px'
                    elementIn.src=line.image  
                }
                if(column=='decouvrir' && l>0){
                    elementIn = document.createElement('a')
                    //elementIn.className=classes.button
                    elementIn.className=clsx(classes.button, classes.elementText)
                    elementIn.innerHTML=line[column]
                    elementIn.href=line.link
                    elementIn.target='_blank'
                    elementIn.rel='nofollow'
                    elementIn.onclick=()=>{mongoWrite({request:'write', collection:'assurance', body:{text:line.id}})}
                }
                element.appendChild(elementIn)
                elementLine.appendChild(element)
                
            }
            tableau.appendChild(elementLine)
        }
        
    })
  
    return(<>
        <div className={classes.tableau} id ='tableau'>
        </div>

    </>)
}

export default Element
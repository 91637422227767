import axios from "axios"

export default function Element(state='view'){ // ne doit pas être async ! (sinon ne peut pas être utilisé à l'ouevrture de guide avec l'actuel code)
    try{
        //on lit les cookie
        let cookie = document.cookie
        cookie = cookie.replaceAll('=', ':')
        cookie = cookie.replaceAll(';', ',')
        cookie = '{'+cookie+'}'
        cookie = JSON.parse(cookie)

        //si il n'y a pas de cookie on en crée un
        if(!Object.keys(cookie).includes('user')){document.cookie = '"user"="'+Math.ceil(Math.random()*1000000000)+'"; expires=3153600000; SameSite=lax'}
        if(!Object.keys(cookie).includes('date')){document.cookie = '"date"="'+(new Date())*1+'"; expires=3153600000; SameSite=lax'}

        //on ajoute la page où on est actuellement (en lui donnant la valeur view/complete)
        document.cookie = '"'+window.location.pathname+'"="'+state+'"; expires=3153600000; SameSite=lax'

        //reset
        if(state=='reset'){
            for(let key of Object.keys(cookie)){
                if(!['user', 'date'].includes(key)){document.cookie ='"'+key+'"=; Max-Age=-1'}
            }
        }
        
        //document.cookie = '"date":"aaa"' +'; expire=99999999;' //pour supprimer un cookie
        //document.cookie = '"date":' +'; Max-Age=-99999999;' //pour supprimer un cookie
        //console.log(document.cookie)
        
        //console.log(cookie)
        return cookie

    }catch(e){
        return {cookieFailed:true}
    }
}

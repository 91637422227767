import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import clsx from 'clsx'
import axios from "axios"

import Component from '../Component/component'
import mongoWrite from '../Component/mongoWrite'

import Ais from './ais.png'
import AisWhite from './aisWhite.png'
import AisBlue from './aisBlue.png'
import AisGreen from './aisGreen.png'
import AisGreen2 from './aisGreen2.png'

import Blank from './blank.png'
import BlankPresentation from './blankPresentation.png'

import Finom from './finom.png'
import FinomPresentation from './finomPresentation.png'

import Orus from './orus.png'
import OrusPresentation from './orusPresentation.png'

import Df from './df.png'
import DfLong from './dfLong.png'
import DfPresentation from './dfPresentation.png'

const useStyles = makeStyles({
    main:{
        margin:'100px 0px 100px 0px',
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',

        //border:'2px solid',
    },
    mainFollow:{
        width:'90vw',
        maxWidth:'800px',
        //border:'2px solid black'
    },

    tableauMain:{
        position:'absolute',
        width:'100vw',
        left:'0px',
        top:'70px',
        padding:'70px 0px 70px 0px',
        
        //background:'linear-gradient(145deg, '+Component.color.main1+','+Component.color.main2+')',
        
    },
    title:{
        marginBottom:'60px',
        fontWeight:'bold',
        fontSize:'2.3em',
        textAlign:'center',
        color:Component.color.main1,
        //color:'white',
        //border:''
    },
    titleText:{
        marginBottom:'20px',
        //color:'rgba(180,180,180)'
    },
    tableau:{
        //marginBottom:'100vw',
        //width:'300vw',

        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        gap:'2vw',
        
        "@media (max-width: 600px)": {
            flexDirection:'column',
        },
    },
    tableauBloc:{
        //width:'100%',
        width:'18vw',
        maxWidth:'200px',
        padding:'0px 10px 30px 10px',
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        //background:'rgba(250,250,250,0.5)',
        //background:'linear-gradient(145deg, rgba(255,255,255,0.8), rgba(255,255,255,0.2))',
        background:'linear-gradient(145deg, rgba(0,0,128,0.1), rgba(0,0,128,0))',
        borderRadius:'10px', //doit être <= à tableau title
        //border:'2px solid black',
        
        transition:'2s',
        "&:hover": {
            filter: "brightness(1.2)",
            marginBottom:'10px',
            transition: "0.5s",
        },
        "@media (max-width: 600px)": {
            width:'70vw',
            maxWidth:'none'
        },
    },
    tableauTitle:{
        width:'100%',
        padding:'10px',
        
        fontSize:'1.1em',
        fontWeight:'bold',
        textAlign:'center',
        color:'white',
        
        background:Component.color.main1,
        borderRadius:'10px 10px 0px 0px', //doit être ele >= à tableauBloc
    },
    tableauImage:{
        marginTop:'20px',
        marginBottom:'30px',
        width:'90%',
        //border:'2px solid',
    },
    tableauButton:{
        padding:'4px 10px 4px 10px',
        background:Component.color.main1,
        color:'white',
        borderRadius:'10px',
        textAlign:'center',
        fontWeight:'bold',
        //border:'2px solid',
        "&:hover": {
            filter: "brightness(1.2)",
            transition: "0.5s",
        },
    },
    tableauText:{
        textAlign:'center',
        color:Component.color.main1,
    },
    tableauSpaceBottom:{
        marginBottom:'400px',
        "@media (max-width: 600px)": {
            marginBottom:'1400px',
        },
        "@media (max-width: 500px)": {
            marginBottom:'1400px',
        },
    },
    subTitle:{
        marginTop:'100px',
        marginBottom:'25px',

        fontSize:'1.5em',
        fontWeight:'bold',
        color:Component.color.main2,
        //border:'2px solid'
    },
    bloc:{
        //height:'100px',
        
        display:'flex',
        flexDirection:'row',
        
        //border:'2px solid',
        "@media (max-width: 600px)": {
            flexDirection:'column',
        },
    },
    blocLeft:{
        width:'30%',
        padding:'2vw',

        display:'flex',
        alignItems:'center',
        justifyContent:'center',

        //border:'2px solid blue',
        "@media (max-width: 600px)": {
            width:'100%',
            padding:'0vw',
            marginTop:'5px',
            marginBottom:'15px',
        },
    },
    blocLeftImage:{
        width:'100%',
        //border:'2px solid',
        "@media (max-width: 600px)": {
            width:'50%',
        },
    },
    blocRight:{
        width:'100%',
        //border:'2px solid blue',
    },
    blocTitle:{
        //marginLeft:'2vw',
        marginBottom:'1vw',
        
        fontWeight:'bold',
        fontSize:'1.4em',
        color:Component.color.main1,
    },
    blocText:{
        //border:'2px solid',
        textAlign:'justify'
    },
    blocButton:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
    },
    button:{
        marginTop:'20px',
        padding:'4px',
        width:'300px',

        borderRadius:'10px',
        textAlign:'center',
        color:'white',
        fontWeight:'bold',
        cursor:'pointer',
        textDecoration:'none',

        //border:'2px solid'
        transition: "2s",
        "&:hover": {
            filter: "brightness(2)",
            transition: "0.3s",
            //transform: "scale(1.05)",
          },
    },
    video:{
        width:'100%',
        //maxWidth:'700px',
        height:'100vw',
        maxHeight:'450px',

        marginTop:'50px',
        borderRadius:'20px',
        boxShadow: 'rgb(200, 200, 200) 0px 20px 10px -10px',

        //border:'2px solid'
    },
    imagePresentation:{
        marginTop:'50px',
        width:'100%',
        borderRadius:'10px',
        //border:'2px solid',
    }

})







function Element(){
    const classes = useStyles()
    //let colorAis = '#00EAEA'
    let colorAis = '#00A7B9'
    let colorBlank = '#0A59FF'
    let colorOrus='#000348'
    let colorDf='#284166'
    let colorFinom = '#FE5664'
    
    const [stateAis, setStateAis] = useState(false)
    const [stateDf, setStateDf] = useState(false)

    async function clic(partenaire){
        let linkList={
            'ais':'https://avance-immediate.fr/',
            'finom':'https://app.finom.co/en/signup?utm_source=partner&utm_medium=cpp&utm_campaign=AIS-banking-.co-France&fnm_product=business&fnm_partner=AIS',
            'blank':'https://lb.affilae.com/r/?p=5faac37a7e67fa729bd876fb&af=388&lp=https%3A%2F%2Fwww.blank.app%2Flanding%2Fouverture-compte-pro-avance-immediate-services%3Futm_campaign%3DAvance%2520Imm%25C3%25A9diate%2520Services%26utm_source%3Daffilae%26utm_medium%3Daffiliation',
            'orus':'https://www.orus.eu/partnerships/avance-immediate-services?utm_campaign=Affilae&utm_medium=partner&utm_source=Avance%20Imm%C3%A9diate%20Services#ae185',
            'df':'mailto:recouvrement@servicealapersonne.fr?subject=Recouvrement - Offre Servicealapersonne.fr&body=Bonjour,%0D%0A Je souhaiterais avoir plus d\'informations sur vos services.%0D%0A En effet je connais un impayé d\'environ XXX euros. %0D%0A Vous pourrez me recontacter au %0D%0A %0D%0A (N\'hésitez pas à joindre les factures à recouvrer).'
        } 
        let mongo = await mongoWrite({request:'write', collection:'partenaire', body:{partenaire:partenaire}})
        console.log(mongo)
        
        if(partenaire=='ais'){setStateAis(true); await new Promise(resolve=>setTimeout(resolve,2000))}
        if(partenaire=='df'){setStateDf(true); await new Promise(resolve=>setTimeout(resolve,1500))}
        //window.open(linkList[partenaire], '_blank') //le popup est bloqué (et même rien ne se passe sur iphone)
        window.location.href = linkList[partenaire]
        
    }
    
    return(
        <div className={classes.main}>
            <div className={classes.mainFollow}>
            
                <div className={classes.tableauMain}>
                    <h1 className={classes.title}>
                        Les partenaires des Services à la Personne
                    </h1>

                    <div className={classes.tableau}>
                        <a href='#ais' style={{textDecoration:'none', color:colorAis}}>
                            <div className={classes.tableauBloc}>
                                <span className={classes.tableauTitle}>Logiciel de gestion dédié SAP</span>
                                <img src={Ais} className={classes.tableauImage}/>
                                <div className={classes.tableauButton} >Version gratuite </div>
                            </div>
                        </a>
                        <a href='#blank' style={{textDecoration:'none', color:Component.color.main1}}>
                            <div className={classes.tableauBloc}>
                                <span className={classes.tableauTitle}>Néobanque professionnelle</span>
                                <img src={Blank} className={classes.tableauImage} style={{margin:'37px 0px 37px 0px'}}/>
                                <span className={classes.tableauButton}>80€ offerts</span>
                            </div>
                        </a>
                        <a href='#orus' style={{textDecoration:'none', color:Component.color.main2}}>
                            <div className={classes.tableauBloc}>
                                <span className={classes.tableauTitle}>Assurance RC Pro et mutuelle</span>
                                <img src={Orus} className={classes.tableauImage}/>
                                <span className={classes.tableauButton} >10% de réduction</span>
                            </div>
                        </a>
                        <a href='#df' style={{textDecoration:'none', color:Component.color.main2}}>
                            <div className={classes.tableauBloc}>
                                <span className={classes.tableauTitle}>Recouvrement d'impayés et CGV</span>
                                <img src={DfLong} className={classes.tableauImage}/>
                                <span className={classes.tableauButton} >40% de réduction</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className={classes.tableauSpaceBottom}></div>


                <div className={classes.titleText}>
                    <strong style={{color:Component.color.main1}}>Afin de toujours mieux vous accompagner</strong>, nous avons négocié d'importantes réductions auprès des <strong style={{color:Component.color.main2}}>meilleurs outils pour les professionnels du Service à la Personne !</strong>
                    <br/>
                    <br/>Vous souhaitez devenir partenaire des professionnels du Service à la Personne ? 
                    Alors <a href = 'https://avance-immediate.fr/contact'>contactez-nous ici !</a> 
                </div>


                <div className={classes.subTitle} style={{color:colorAis}} id='ais'>
                    Avance Immédiate Services - Logiciel de gestion
                </div>
                <div className={classes.bloc}>
                    <div className={classes.blocLeft}>
                        <img src={Ais} className={classes.blocLeftImage}/>
                    </div>
                    <div className={classes.blocRight}>
                        <div className={classes.blocText}>
                            Gérez simplement votre activité au sein d'une <strong style={{color:colorAis}}>solution dédiée aux professionnels du Service à la Personne</strong> :
                            Facturation, avance immédiate, attestations fiscales, nova, suivi d'activité, ...
                            <br/>
                            <br/><strong style={{color:colorAis}}>Habilité par l'Urssaf</strong> à l'API Tiers de prestation, proposez sereinement l'Avance Immédiate du crédit d'impôt grâce à ses nombreuses fonctionnalités exclusives.
                            <br/><strong style={{color:colorAis}}>Ses experts vous accompagnent</strong> gratuitement dans votre habilitation API Tiers de prestation et peuvent la réaliser pour vous gratuitement !
                            <br/>
                            <br/>Solution tout-en-un ou complémentaire, Avance Immédiate Services est l'outil tout désigné pour les professionnels du Service à la Personne !
                        </div>
                        <div className={classes.blocButton}>
                            <div className={classes.button} style={{background:colorAis}} onClick={()=>{clic('ais')}}>Version gratuite !</div>
                            <br/>{(false && stateAis) ? <strong style={{color:colorAis}}>Utiliser le code "SAP2024" lors du choix de votre licence</strong>:''}
                        </div>
                    </div>
                </div>

                <iframe
                    className={classes.video}
                    src="https://www.youtube-nocookie.com/embed/5ZsAT0PVtFI?rel=0"
                    title="Avance Immediate Services - Video de demonstration"
                    frameborder="0"
                    allowFullScreen
                ></iframe>





                <div className={classes.subTitle} style={{color:colorBlank}} id='blank'>
                    Blank - Banque en ligne Professionnelle
                </div>
                <div className={classes.bloc}>
                    <div className={classes.blocLeft}>
                        <img src={Blank} className={classes.blocLeftImage}/>
                    </div>
                    <div className={classes.blocRight}>
                        <div className={classes.blocTitle} style={{color:colorBlank}}></div>
                        <div className={classes.blocText}>
                            Blank est une néo-banque française, <strong style={{color:colorBlank}}>filiale de Crédit Agricole</strong>.
                            <br/> Avec plus de 20 000 professionnels qui lui font déjà confiance, Blank fait d'ores et déjà partie des leaders français de son marché.
                            <br/>
                            <br/><strong style={{color:colorBlank}}>Dédiée aux indépendants et auto entrepreneurs</strong>, la néobanque Blank est parfaitement adaptée à vos besoins et budget.
                            <br/>
                            <br/>Ses solutions complètes vous permettent de gérer sereinement votre activité. 
                            <br/>Enfin sa plateforme ergonomique vous accompagne aux bureaux et sur le terrain grâce à une interface simple d'utilisation et sans frais caché.
                        </div>
                        <div className={classes.blocButton}>
                            <div className={classes.button} style={{background:colorBlank, width:'220px'}} onClick={()=>{clic('blank')}}>Profitez de 80€ offerts  !</div>
                        </div>
                    </div>
                </div>
                <img className={classes.imagePresentation} src={BlankPresentation}/>
                



                <div className={classes.subTitle} style={{color:colorOrus}} id='orus'>
                    Orus - Assurances et Mutuelles
                </div>
                <div className={classes.bloc}>
                    <div className={classes.blocLeft}>
                        <img src={Orus} className={classes.blocLeftImage}/>
                    </div>
                    <div className={classes.blocRight}>
                        <div className={classes.blocText}>
                            Orus est une <strong style={{color:colorOrus}}>société d'assurance française pour les indépendants</strong>.
                            <br/>
                            <br/><strong style={{color:colorOrus}}>Humaine et transparente</strong>, Orus a crée des solutions claires et sans frais cachés, afin de protégez vous et vos collaborateurs à moindres frais.
                            <br/>
                            <br/>Orus propose des <strong style={{color:colorOrus}}>Responsabilités Civile Professionnelles (RC Pro), mutuelles et prévoyances</strong>, compétitives et s'occupe même de résilier votre ancien contrat !
                            <br/>
                        </div>
                        <div className={classes.blocButton}>
                            <div className={classes.button} style={{background:colorOrus, width:'250px'}} onClick={()=>{clic('orus')}}>Profitez de 10% de réduction !</div>
                        </div>
                    </div>
                </div>
                <img className={classes.imagePresentation} src={OrusPresentation}/>


                <div className={classes.subTitle} style={{color:colorDf}} id='df'>
                    Cabinet DF & Associés - Recouvrement
                </div>
                <div className={classes.bloc}>
                    <div className={classes.blocLeft}>
                        <img src={Df} className={classes.blocLeftImage}/>
                    </div>
                    <div className={classes.blocRight}>
                        <div className={classes.blocText}>
                            DF & Associés est un cabinet de recouvrement présent sur tout le territoire <strong style={{color:colorOrus}}>avec une forte expérience dans le Service à la Personne</strong>.
                            <br/>
                            <br/>Avec des taux de réussite record depuis sa création il y a 31 ans, le cabinet DF s'occupe de recouvrer vos créances litigieuse à partir d'une simple facture, <strong style={{color:colorDf}}>tout en conservant le lien commercial avec votre client</strong>.
                            <br/>
                            <br/><strong style={{color:colorDf}}>Rémunéré uniquement à la réussite</strong>, le cabinet DF & Associé peut également vous accompagner dans la rédaction de vos CGV ! 
                        </div>
                        <div className={classes.blocButton}>
                            <div className={classes.button} style={{background:colorDf, width:'250px'}} onClick={()=>{clic('df')}}>Profitez de 40% de réduction !</div>
                            {stateDf? 
                                <strong style={{color:colorDf, textAlign:'center'}}>
                                    Contactez nous à <a href="mailto:recouvrement@servicealapersonne.fr?subject=Recouvrement - Offre Servicealapersonne.fr&body=Bonjour,%0D%0A je souhaiterais avoir plus d'informations sur vos services.%0D%0A En effet je connais un impayé d\'environ XXX euros. %0D%0A Vous pourrez me recontacter au %0D%0A %0D%0A (N'hésitez pas à joindre les factures à recouvrer)">recouvrement@servicealapersonne.fr</a> <br/>en indiquant le montant à recouvrir
                                    </strong>
                            :''}
                        </div>
                    </div>
                </div>
                <img className={classes.imagePresentation} src={DfPresentation}/>


                <div className={classes.subTitle} style={{color:Component.color.main2, fontSize:'1.2em', marginBottom:'10px'}}>Vous souhaitez vous aussi devenir partenaire du Service à la Personne ?</div>
                <a href='https://avance-immediate.fr/contact' style={{textDecoration:'none'}}>
                    <div className={classes.blocButton}>
                        <div className={classes.button} style={{background:Component.color.main2, width:'200px'}}>Contactez-nous !</div>
                    </div>
                </a>

            </div>
        </div>
    )
}

export default Element
import {Helmet} from 'react-helmet'
import clsx from 'clsx'
import { makeStyles } from "@mui/styles"
import { useParams } from "react-router-dom"

import Component from '../Component/component'
import mongoWrite from '../Component/mongoWrite'

//import Photo from './photo2.jpg'
import Photo from './woman2.jpg'

//import Background from './background3.jpg'
import Guide from './guide.png'
import Barometre from './barometre.png'
import Annuaire from './annuaire.png'


const useStyles = makeStyles({
  main:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },
  line1:{
    padding:'70px 0px 0px 0px',
    textAlign:'center',
    
    fontSize:'2vw', //2em
    fontWeight:'bold',
    //color:'orange',
    "@media (max-width: 1400px)": {
      fontSize: "2em",
    },
  },
  line2:{
    //marginTop:'50px',
    padding:'70px 0px 100px 0px',
    display:'flex',
    flexDirection:'row',

    //background:`url(${Background})`,  
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center", //center
    backgroundSize: "cover", //cover
    //border:'2px solid '+Component.color.main1,
    "@media (max-width: 1100px)": {
      flexDirection:'column',
    },
  },

  photo:{
    width:'60vw',
    //padding:'0px 5vw 0px 10vw',
    
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    
    //border:'2px solid'
    "@media (max-width: 1100px)": {
      width:'100%',
    },
  },

  "@keyframes photoAlone": {
    "0%":  { borderRadius:'20px 120px 20px 120px' },
    "50%": { borderRadius:'120px 20px 120px 20px' },
    //"50%": { borderRadius:'80px 120px 20px 60px' },
    //"75%": { borderRadius:'80px 120px 60px 80px' },
  },
  photoAlone:{
    height:'30vw',
    //minHeight:'500px',
    maxHeight:'500px',
    width:'40vw',
    maxWidth:'800px',
    //marginRight:'10vw',

    display:'flex',
    flexDirection:'column',
    justifyContent:'end',
    
    background:`url(${Photo})`,  
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center", //center
    backgroundSize: "cover", //cover
    
    borderRadius:'20px 120px 20px 120px',
    animation: "$photoAlone 15s infinite linear",
    cursor:'pointer',
    transition:'0.3s',
    "&:hover": {
      filter:'brightness(1.1)',
    },
    "@media (max-width: 1100px)": {
      height:'60vw',
      width:'80vw',
    },
  },

  photoTitle:{
    //width:'100%',
    padding:'20px 5vw 30px 5vw',
    textAlign:'center',
    fontSize:'1.5em',
    fontWeight:'bold',
    //border:'2px solid black'
    "@media (max-width: 1100px)": {
      paddingBottom:'100px',
    },
  },

  "@keyframes left": {
    "0%": { marginLeft: "40vw", width: "0px", opacity: "0" },
    //"30%": { opacity: "0" },
  },
  menu:{
    width:'40vw', // 100 - photo.width

    display:'flex',
    flexDirection:'column',
    alignItems:'start',
    justifyContent:'center',

    animation: "$left 2s",
    //border:'2px solid',
    "@media (max-width: 1100px)": {
      width:'100%',
      alignItems:'center',
    },
  },

  menuLink:{
    textDecoration:'none',
    cursor:'pointer',
  },
  "@keyframes border": {
    "0%": { borderTop:'4px solid orange', borderLeft:'4px solid orange'},
    "50%": { borderBottom:'4px solid orange', borderRight:'4px solid orange' },
  },
  menuChoice:{
    width:'30vw',
    maxWidth:'500px',
    marginBottom:'20px',
    
    padding:'30px',
    borderRadius:'20px',
    boxShadow:'2px 2px 8px 3px rgba(200,200,200)',
    
    transition:'0.7s ease-out',
    "&:hover": {
        boxShadow:'6px 6px 0px '+Component.color.main2,
        filter:'brightness(1.1)',
        //boxShadow:'2px 2px 8px 3px orange',
        transition:'0.3s ease-in-out',
        //borderRight:'6px solid orange',
        //animation: "$border 2s",
    },
    "@media (max-width: 1100px)": {
      width:'80vw',
    },
  },
  menuTitle:{
    fontWeight:'bold',
    fontSize:'1.4em',
    color:Component.color.main1,
    //border:'2px solid',
  },
  menuChapter:{
    marginTop:'20px',
    
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',

    //border:'2px solid',
  },
  menuText:{
    minHeight:'80px',
    paddingRight:'15px',

    fontSize:'1.1em',
    color:Component.color.main2,
    textAlign:'justify',
    
    //border:'2px solid',
    "@media (max-width: 1100px)": {
      width:'100%',
      paddingRight:'0px',
    },
  },
  menuLogo:{
    width:'4vw',
    //minWidth:'75px',
    height:'4vw',
    //minHeight:'75px',
    //border:'2px solid',
    "@media (max-width: 1100px)": {
      visibility:'hidden',
      width:'0px',
    },
  },

  line3:{
    width:'100vw',
    padding:'50px 0px 50px 0px',
    textAlign:'center',
    background:Component.color.main1,
    //filter:'brightness(2)',
    color:'white',
    lineHeight:'1.8em',
    //fontWeight:'bold',
    fontSize:'1.15em',
  },

  line4:{
    padding:'100px 50px 100px 50px',

    display:'flex',
    flexDirection:'row',
    alignItems:'center',

    color:Component.color.main2,
    fontWeight:'bold',
  },


})

function Element(props){
  const classes = useStyles()
  //let { id } = useParams()
  let blue='rgba(50,100,220)'
  let orange = 'rgba(250,100,200)'
  //let orange = 'rgba(100,200,250)'
  
  return(<>
    <Helmet>
      <title>Guide SAP professionnel</title>
      <meta name="description" content="Le guide complet et gratuit pour les professionnels du Service à la Personne. Lancer et développer votre activité sereinement."/>
    </Helmet>

    <div className={classes.main}>
      
      <h1 className={classes.line1} style={{color:Component.color.main1}}>
        Le <span style={{color:Component.color.main2}}>Guide Ultime</span> pour 
        <br/>Développer votre activité de <span style={{color:Component.color.main2}}>Service à la Personne</span> !
      </h1>      

      <div className={classes.line2}>
          <div className={classes.photo}>
            <div className={classes.photoAlone} onClick={()=>{window.location='/guide'}}>

            </div>
            <a className={classes.photoTitle} style={{color:Component.color.main2, textDecoration:'none'}} href='/guide'>
                <span style={{color:Component.color.main1}}>Retrouver l'ensemble des informations et outils</span>
                <br/>Pour se lancer ou faire <span style={{borderBottom:'4px solid'}}>croître son activité</span> !
              </a>
          </div>


          <div className={classes.menu}>

            <a className={classes.menuLink} href='/guide'>
              <div className={classes.menuChoice}>
                <div className={classes.menuTitle}>Le Guide Ultime des professionnels du SAP</div>
                <div className={classes.menuChapter}>
                  <div className={classes.menuText}>
                    Formation gratuite, cours certifiants, livre blanc, ...
                    <br/>Tout pour se lancer et développer sereinement son activité de Service à la Personne !
                  </div>
                  <img src={Guide} className={classes.menuLogo}/>
                </div>
              </div>
            </a>

            <a className={classes.menuLink} onClick={()=>{props.changePopup(); mongoWrite({request:'write', collection:'barometre', body:{source:'presentation'}})}}>
              <div className={classes.menuChoice}>
                <div className={classes.menuTitle}>Le Baromètre du SAP</div>
                <div className={classes.menuChapter}>
                  <div className={classes.menuText} >
                    Prix horaire de référence, Etudes de marchés, Actualités, ... C'est ici que vous pourrez définir votre grille tarifaire ou suivre les évolutions du secteur.
                  </div>
                  <img src={Barometre} className={classes.menuLogo}/>
                </div>
              </div>
            </a>

            <a className={classes.menuLink} onClick={()=>{props.changePopup(); mongoWrite({request:'write', collection:'annuaire', body:{source:'presentation'}})}}>
              <div className={classes.menuChoice}>
                <div className={classes.menuTitle}>Annuaire SAP</div>  
                <div className={classes.menuChapter}>
                  <div className={classes.menuText}>
                    Gagnez de nouveaux clients et augmentez votre visibilité, grâce à l'annuaire SAP gratuit !
                  </div>
                  <img src={Annuaire} className={classes.menuLogo}/>
                </div>
              </div>
            </a>
      
        </div>

      </div>

      <div className={classes.line3}>
        " Issus du Service à la Personne, 
        <br/>Nous avons souhaité créer un <span style={{color:Component.color.main2}}>manuel complet</span>, qui regroupe les spécificités du secteur,
        <br/>Et l'ensemble des outils que nous aurions souhaités connaitre plus tôt,
        <br/>Afin de <span style={{color:Component.color.main2}}>lancer et développer votre activité de service aux particuliers</span>. "
        <br/><div style={{color:Component.color.main2, fontWeight:'bold'}}>L'équipe servicealapersonne.fr</div>
      </div>

      <div className={classes.line4}>
        <div>
          Vous souhaitez participer au développement du Services à la Personne ?
        </div>
        <a className={(Component.style()).button} style={{marginLeft:'15px'}} href='/Contact'>
          Devenir partenaire !
        </a>
      </div>

    </div>
  
    </>)
}

export default Element
import { makeStyles} from "@mui/styles"
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import mongoWrite from '../../Component/mongoWrite'

import Phone from './phoneColor.png'
import PhoneWhite from './phoneWhite.png'
import Mail from './mailColor.png'
import MailWhite from './mailWhite.png'


const useStyles = makeStyles({
    main:{
        marginTop:'25px',
        marginBottom:'20px',
        fontWeight:'bold',
        color:Component.color.main1,

        display:'flex',
        flexDirection:'column',
        alignItems:'center',

    },
    line:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
    },
    contact:{
        width:'300px',
        marginTop:'10px',
        padding:'10px',

        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        
        color:'white',
        textAlign:'center',
        borderRadius:'50px',
        //background:ComponentArticle.color.column1Dark,//'blue',
        background:Component.color.main1,//'blue',
    },
    logo:{
        width:'30px',
        marginTop:'3px',
        cursor:'pointer',
        //filter:'brightness(3)'
        //border:'2px solid',
    },
    input:{
        width:'200px',
        padding:'7px',
        borderRadius:'20px',
        color:Component.color.main1,
        textAlign:'center',

        //display:'flex',
        //border:ComponentArticle.color.main1,
    },
    button:{
        marginTop:'10px',
        marginLeft:'20px',
        padding:'12px',

        cursor:'pointer',
        borderRadius:'50px',
        background:Component.color.main1,
        color:'white',
        fontWeight:'bold',
    },
    message:{
        marginTop:'5px',
        fontSize:'0.9em',
        textAlign:'center',
        color:Component.color.main2,
    }
})

function Element(props){
  const classes = useStyles()//ComponentArticle.useStyles()
  const [selection, setSelection] = useState('mail')
  function changeSelection(data){
    setSelection(selection=='mail'?'phone':'mail')
  }

  async function send(){
    let contact = document.getElementById('input').value
    if(contact.length<8){
        document.getElementById('message').innerHTML = 'Veuillez indiquer une adresse mail ou un numéro de téléphone'
        return ''
    }
    let body = {
        mail:selection=='mail'?contact:undefined,
        phone:selection=='phone'?contact:undefined
    }
    let mongo = await mongoWrite({request:'write', collection:'contactAIS', body:body})
    document.getElementById('button').style.background = 'rgba(200,200,200)'
    document.getElementById('message').innerHTML = 'Merci de votre confiance. <br/>Vous serez recontacté sous peu par les équipes d\'Avance Immédiate Services.'
  }
  
    return(
    <div className={classes.main}>
        Soyez accompagné par notre partenaire Avance Immédiate Services :
        <div className={classes.line}>

            <div className={classes.contact} >
                <div>
                    {selection=='phone' ? 
                        <img src={Phone} alt='' className={classes.logo} id='phone'/>
                        :<img src={PhoneWhite} alt='' className={classes.logo} id='phone' onClick={changeSelection}/>
                    }
                </div>
                <div>
                    {selection=='mail' ?
                        <img src={Mail} alt='' className={classes.logo} id='mail'/>
                        :<img src={MailWhite} alt='' className={classes.logo} id='mail' onClick={changeSelection}/>
                    }
                </div>
                <input className={classes.input} placeholder={selection=='mail'?'Veuillez indiquer votre mail':'Veuillez indiquer votre numéro'} id='input'></input>
            </div>

            <div className={Component.style().button} style={{marginTop:'8px', marginLeft:'20px',}} onClick={send} id='button'>Envoyer</div>

        </div>
        <div className={classes.message} id='message'></div>
    </div>
    )
}

export default Element
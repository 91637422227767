import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'


import intro from './city7.jpg'
//import network from './phone.jpg'

function Element(props){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>6 Moyens efficaces de trouver des clients</title>
      <meta name="description" content="Guide complet : Retrouver les 6 principaux leviers pour augmenter votre activité."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        6 leviers pour une activité en Croissance
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 29 août 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'800px', marginTop:'30px', marginBottom:'-20px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Comme pour l'ensemble des professionnels, savoir gagner des clients est déterminant dans la croissance de son activité.
        <br/>
        <br/>Avec l'ère du numérique, de nouvelles opportunités sont apparues, permettant aux professionnels de décupler les prospects potentiels et pouvoir mieux présenter leurs travaux.
        <br/>Mais les méthodes plus "traditionnelles" restent toujours d'actualité, elles qui n'ont plus à prouver leur efficacité !
        <br/>
        <br/>Nous allons donc analyser 6 moyens de gagner vos premiers clients ou continuer à croitre, en définissant les moyens les plus efficaces en fonction de votre activité et clientèle visée.
      </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        1. Annuaire du Service à la Personne
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Gratuit, rapide et efficace, soyez vu par l'ensemble des particuliers qui recherche un prestataire de service.
        <br/>
        <br/>Indiquez simplement votre zone géographique et votre contact. 
        <br/>Vous pourrez si vous le souhaitez ajouter une description des prestations que vous proposez, votre grille tarifaire, des photos, ...
        <br/>
        <br/>Si vous avez un site web, l'ajouter en description permettra même d'améliorer grandement son référencement ! 
      </div>
      <a className={Component.style().button} onClick={props.changePopup} style={{margin:'20px 0px 20px 0px'}}>
        Ajouter sa structure à l'annuaire SAP
      </a>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        2. Plateformes de mise en relation
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Leboncoin, Gens de confiance, ParuVendu, ... il existe un grand nombre de plateformes de mise en relation visant les particuliers.
        <br/>
        <br/>Certaines sont généralistes (comme Leboncoin), d'autres spécifiques au Service à la personne (Annuaire SAP), ou encore dédiées à un type d'activité (cours à domicile, jardinage, ...).
        <br/> Beaucoup sont gratuites, ou proposent une mise en avant pour quelques euros. A ne pas confondre avec les coopératives ou plateformes de mise à disposition, qui demandent des commission bien plus élevées.
        <br/>
        <br/>On ne pourra évidemment par être exhaustif ici, mais nous allons tenter de vous présenter celles qui ont attiré notre attention :
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Leboncoin</span> : Le plus populaire avec plus de 300 millions de visites par an, il référence autant des objets d'occasion, des offres immobilières, que des prestations de services.
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Gens de confiance</span> : Le plus sécurisant, afin de pouvoir accéder au site, il faut être parrainé par 3 anciens utilisateurs. L'accent est davantage porté sur la qualité des prestations. 
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>SuperProf</span> : Dédié aux cours particuliers, il est gratuit pour les professionnels.
        <br/><br/>
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        3. Réseaux sociaux
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Nous en entendons parler absolument partout, mais les réseaux sociaux sont-ils vraiment intéressants dans le cadre du Service à la Personne ?
        <br/>
        <br/>Cela dépend.
        <br/>Cela dépend de la clientèle qui fait appel à votre activité, de la clientèle que Vous avez choisie de viser, de votre relation avec ces outils, de la taille de votre équipe, etc.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Facteur Âge</span>
        <br/>En effet chaque réseau est adressé à une certaine tranche d'âge : X et Facebook sont plutôt consommés par les adultes, Instagram les jeunes adultes et TikTok les adolescents. 
        <br/>
        <br/>On notera qu'il est important de définir sa cible en amont. Pour des cours particuliers, nous n'auront pas du tout la même communication ni les mêmes réseaux si nous communiquons aux parents ou à leurs enfants.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Facteur Activité</span>
        <br/>L'utilisation d'Instagram peut être plus intéressant pour des prestations comme le coaching sportif, le jardinage, ou les soins esthétiques.
        <br/>Facebook avec ces nombreux groupes, se prête plus aux cours particuliers, entretien de la maison, garde d'enfants, ...
        <br/>
        <br/>
        </div>
        

        

        <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        4. Site web
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Quoi de mieux que sa propre page internet pour promouvoir son activité !
        <br/>Présentation de votre activité, de vos références, tarifs et horaires : Votre image est professionnelle et votre vitrine accessible 24h/24.
        Une page de contact vient parfaire le tout, vous apportant de nouveaux prospects sans effort.
        <br/>
        <br/>Avoir un site web oui, mais il doit être vu !
        Pour cela il doit : 
        <ul>
          <li>Être inclu dans votre bio et posts sur les réseaux ;</li>
          <li>Être facilement trouvable sur les moteurs de recherche (en se limitant à une zone géographique ou payer pour être en première place) ;</li>
          <li>Porter votre adresse mail (contact@monjardinier.fr est étrangement plus pro que jeandupontdu93@wanadoo.com).</li>
        </ul>
        Si vous souhaitez faire réaliser votre site web, n'hésitez pas à contacter nos professionnels :
      </div>
      <a className={Component.style().button} href='/contact' style={{margin:'20px 0px 20px 0px'}}>
        Passer au niveau supérieur avec votre site Web
      </a>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        5. Communication traditionnelle
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Flyers, journaux, panneaux publicitaires et radio, que penser des "anciennes méthodes" ? Sont-elles dépassées et impossible à rentabiliser ? Rien n'est moins sûr.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Boîtes aux lettres et tracts</span>
        <br/>Les tracts publicitaires retrouvés dans nos boîtes aux lettres semblent une époque révolue, pourtant celle-ci a toujours un très bon impact sur les ventes.
        <br/>Les frais postaux et de l'impression ayant augmentés, les grandes marques ont réduit la voilure, laissant autant de place pour les prestations de services, par nature locales.
        <br/>Evidemment si vous souhaitez étudier cette option, nous ne pourrons que vous conseiller de privilégier des méthodes responsables (papiers recyclés et surtout non plastifiés). 
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Boutiques partenaires</span>
        <br/>Des flyers ou cartes de visites exposés chez des boutiques partenaires, représentera toujours une communication efficace.
        <br/>Cela peut être un excellent moyen de générer du cross selling (mon amie qui fait de la garde d'enfant m'apporte des clients en cours particuliers et vice-versa).
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Publicités</span>
        <br/>Avec la modification de notre consommation, afficher son activité sur un journal local, une radio locale ou un panneau publicitaire est devenu plus abordable qu'à l'accoutumée.
        <br/>De plus il est possible d'optimiser sa communication, en se focalisant sur les supports au plus proches de vos prospects :
        <ul>
          <li>Un journal d'offres immobilières, pour de l'entretien de maison ;</li>
          <li>Des panneaux publicitaires prochent d'une école, pour des cours particuliers ;</li>
          <li>Les journaux locaux, pour l'assistance à domicile ou les petits travaux.</li>
        </ul>  
        Innover vous permettra d'apparaitre là où aucun concurrent n'est visible, quand une approche ultra-classique vous permettra de réduire les risques et pourquoi pas prendre des espaces très lucratifs délaissés à tort.
        <br/>
        <br/>
      </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        6. Prospection physique
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Quoi de plus naturel dans notre secteur, quelle que soit l'évolution du numérique, la confiance aura toujours une place prépondérante quand nous confions notre maison, nos enfants ou nos ainés.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Salons</span>
        <br/>Il existe de nombreux salons à travers le territoire qui permettent aux professionnels de présenter leur proposition de valeur, que celle-ci soit liée à la qualité des prestations ou leurs innovations.
        <br/>
        <br/>On notera :
        <ul>
          <li>Le salon des Services à la Personne à Paris (chaque fin novembre) ;</li>
          <li>Le salon des paysagistes à Lyon (début novembre)</li>
          <li>N'hésitez pas à <a href='/contact'>nous proposez les votres</a></li>
        </ul> 

        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Groupes d'entrepreneurs</span>
        <br/>En plus de réduire la solitude que l'on peut connaitre en lançant son activité, les groupes d'entrepreneurs permettent le partage de clients et la réalisation de cross-selling.
        <br/>Il existe des réseaux ou des groupes exclusivement locaux. On citera comme exemple de réseau la BNI qui connait des antennes un peu partout en France. 
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Bouche à oreille</span>
        <br/>Que de plus satisfaisant que d'être recommandé pour son travail.
        <br/>La plus ancienne méthode est la plus efficace, des clients satisfaits sont des investissements sur le long terme.
        <br/>
        <br/>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Il y donc beaucoup de voies à explorer pour faire croître votre activité.
        <br/>Toutes ne conviendront peut être pas à votre marché ou votre type de service, et il pourrait être contre-productif de se dissiper dans de trop nombreux modes de communication. 
        <br/>
        <br/>Il n'y a pas de recette magique, mais il n'y a pas non plus de mauvais choix.
        <br/>La question est finalement comment attribuer ses ressources (temps et financier) pour générer le plus d'activité possible ?
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element
import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"

import Component from './component'
import mongoWrite from './mongoWrite'

const useStyles = makeStyles({
    main:{
        position:'fixed',
        zIndex:'20',
        width:'100vw',
        height:'100vh',
        
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    bloc:{
        zIndex:'3',
        padding:'50px 50px 20px 50px',
        background:'white',
        borderRadius:'20px',
        border:'3.5px solid',
        boxShadow:'2px 2px 8px 3px rgba(200,200,200)',

        fontSize:'1.2em',
        fontWeight:'bold',
        textAlign:'center',
        color:Component.color.main1,
    },
    input:{
        marginTop:'5px',
        width:'250px',
        padding:'10px',
        textAlign:'center',

        borderRadius:'10px',
        border:'1px solid '+Component.color.main2,
        boxShadow:'2px 2px 8px 3px '+Component.color.sub1,

    },
    messageSend:{
        fontSize:'0.8em',
        fontWeight:'none',
        color:Component.color.main2
    }

})


function Element(props){
    const classes = useStyles()

    async function send(collection){
        console.log(collection)
        let mail = '' //to save outside
        if(collection=='newletter'){
            //extract and clean mail
            mail = document.getElementById('mail').value
            mail = mail.replaceAll(' ','')
            mail = mail.toLowerCase()
            mail = mail.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
            //test mail
            let regex = new RegExp('^[a-z0-9-_.]{1,}[@][&-z0-9-_.]{1,}[.][a-z0-9]{1,}$')
            regex = regex.test(mail)
            if(!regex){
                document.getElementById('popupMessage').innerHTML = 'Veuillez indiquer une adresse mail valide'
                collection = collection+'Failed'
            }
        }
        //record
        let response = await mongoWrite({request:'write', collection:collection, body:{mail:mail}})
        //document.getElementById('popupMessage').innerHTML = response.data
        //show
        if(collection=='newletter'){
            document.getElementById('popupMessage').innerHTML = 'Merci, nous reviendrons vers vous dès qu\'elles seront disponibles !'
            document.getElementById('popupSend').style.background = 'rgba(200,200,200)'
        }
    }

    
    return(
        <div className={classes.main} onClick={()=>{props.changePopup(false)}}>
            <div className={classes.bloc} onClick={(e)=>{e.stopPropagation()}}>
                Le Baromètre et l'Annuaire
                <br/>sont en cours de finalisation... 
                <br/>
                <br/>Revenez ultérieurement pour les découvrir !    
                <br/>
                <input className={classes.input} placeholder='Ou soyez informé par mail !' id='mail'/>
                <br/>
                <br/><a className={Component.style().button} style={{background:Component.color.main2, marginRight:'20px'}} onClick={()=>{send('newletter')}} id='popupSend'>
                    Envoyer
                </a> 
                <a className={Component.style().button} onClick={()=>{send('newletterClose'); props.changePopup(false)}}>
                    Fermer
                </a> 
                <br/>
                <br/>
                <div className={classes.messageSend} id='popupMessage'></div>
            </div> 
        </div>
    )
}

export default Element
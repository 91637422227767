import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'


import intro from './accueil.png'
import nouvelleDeclaration from './nouvelleDeclaration.png'
import reglementation from './reglementation.png'
import transmettre from './transmettre.png'

function Element(){
  const classes = ComponentArticle.useStyles()
 
    return(<>
    <Helmet>
      <title>Guide agrément Nova - Devenir SAP</title>
      <meta name="description" content="Réaliser votre déclaration / agrément Nova grâce à notre guide complet dédié aux Services à la Personne."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Déclaration / Agrément Nova
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 29 septembre 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'1000px', marginTop:'30px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'1000px', marginTop:'10px', marginBottom:'-20px'}}>Le site de la plateforme nova.entreprises.gouv.fr</div>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Être agréé Service à la personne est fondamental afin de réaliser des prestations au domicile des particuliers.
        En effet grâce à cet agrément, vos clients seront <strong>remboursés de 50% du montant de vos prestations sous forme de crédit d'impôt</strong>.
        <br/>
        <br/>Nous avons vu dans un précédent article la définition du Service à la Personne, ses avantages et limitations.
        <br/>Cet article est dédié à la demande à réaliser sur le site Nova, afin de devenir Service à la Personne !
        </div>




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Créer son compte Nova
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Pour devenir Service à la Personne et permettre à vos clients de bénéficier des 50% de crédit d'impôt, 
        <br/>La structure doit tout d'abord créer un compte sur le site Nova :
      </div>
      <a className={Component.style().button} style={{margin:'20px 0px 20px 0px'}} href='https://nova.entreprises.gouv.fr/auth/realms/Nova/login-actions/registration?client_id=nova&tab_id=IlknumJmeFE' target='_blank' rel='nofollow'>
          Créer son compte Nova
      </a>
      <div className={classes.text}>
          <span style={{color:'white'}}> .......... </span>
          Vous pourrez ici renseigner les différentes informations demandées.
          <br/>Afin de pouvoir cocher "j'ai lu et j'accepte les conditions générales d'utilisation", vous devez d'abord cliquer sur "CGUs" afin de pouvoir les consulter.
          <br/>Une fois les CGU lues et la case cochée, vous pouvez créer votre compte en cliquant sur 'Enregistrement".
      </div>



     <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Créer une demande d'agrément
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La première chose à réaliser est de se connecter sur votre compte Nova fraichement créé.
        <br/>
        <br/>Une fois sur votre tableau de bord vous vous dirigerez vers "Mes demandes", puis cliquez sur "Créer une nouvelle demande".
     </div>
     <img className={classes.image} src={nouvelleDeclaration} style={{width:'80vw', maxWidth:'800px', marginTop:'10px', marginBottom:'20px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      

     <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        1. Choisir des activités
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Vous pourrez alors choisir les activités que vous souhaitez proposer à vos clients. 
        <br/>Les activités indiquées doivent aussi apparaître sur les statuts de votre structure.
        <br/>
        <br/>Si vous n'avez pas encore défini les statuts de votre structure, il peut être intéressant d'y indiquer au moins l'ensemble des "Activités relevant uniquement de la déclaration", afin de pouvoir diversifier votre activité plus tard par exemple.
        <br/>
        <br/>Certaines activités au contact de personnes fragiles requièrent d'autres agréments, uniquement attribués par votre conseil départemental ou l'Etat.
        <br/>
        <br/>Afin de trouver de plus amples informations sur ce que comprennent les différents types d'activités, vous pourrez consulter <a href='/articleSap'>notre article dédié au SAP</a>.
     </div>
      

     <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        2. Périmètre et modes d'interventions
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Vous pourrez alors pour chacune des activités sélectionnées, indiquer le ou les modes d'interventions et le périmètre d'intervention.
        <br/>
        <br/>Les modes d'interventions :
        <ul>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Prestataire : </span>
            C'est la structure qui emploie l'intervenant.
          </li>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Mise à disposition : </span>
            L'intervenant est ici aussi employé par la structure, mais c'est le particulier qui prend en charge l'organisation du travail de l'intervenant.
          </li>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Mandataire : </span>
            Le particulier emploie l'intervenant. La structure gère elle la partie administrative pour le compte du particulier.
          </li>
        </ul>
        Le périmètre est par défaut défini pour la France entière, ce qui permet en cas de déménagement ou croissance de l'activité, de ne pas avoir à réaliser de démarches complémentaires.
        <br/>Le périmètre sera réduit à un unique département pour les activités nécessitant un agrément supplémentaire.
     </div>


     <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        3. Réglementation SAP
      </div>
      <img className={classes.image} src={reglementation} style={{width:'80vw', maxWidth:'800px', marginTop:'0px', marginBottom:'20px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Le site reprend ici les différentes règlementations et limitations attachées au statut de Service à la Personnne.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Condition d'activité exclusive : </span>
        Votre structure doit seulement réaliser des prestations parmis les activités selectionnées et uniquement pour des particuliers.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Activité exercé à partir ou à destination du domicile : </span>
        Les prestations doivent être réalisées vers, à partir ou directement au domicile du particulier (notamment pour les prestations de conduite et d'accompagnement).
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Déclaration Nova : </span>
        A la fin de chaque trimestre et de fin d'annnée, la structure doit déclarer différentes informations sur le site Nova (chiffre d'affaires, nombre d'heures prestées, ...)
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Factures normées : </span>
        Informations que vos futures factures devront présenter.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Réalisation d'attestations fiscales : </span>
        La structure doit fournir à chacun de ses clients en début d'année, une attestation fiscale qui reprend l'ensemble des prestations effectuées l'année passée.
        <br/>Ce document permettra aux particuliers de prouver la réalisation des prestations en cas de contrôle fiscal.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Règles du droit de la consommation : </span>
        Les professionnels du Service à la Personne doivent respecter le droit de la consommation.
        <br/>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Apposition du logo SAP : </span>
        Tous vos documents commerciaux doivent afficher le logo du service à la personne.
        <br/>
        <br/>Une fois ces informations prises en compte vous pouvez cliquer sur "J'ai compris les principes de la réglementation SAP", puis cliquer sur Suivant.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        4. Engagement CAE
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Cette partie aborde plus en détails la notion de Condition d'Activité Exclusive.
        <br/>Une fois prise en compte, vous pouvez cliquer sur "Je m'engage à respecter la CAE".
        <br/>
        <br/>
        Certains professionnels peuvent en être dispensés, et devront pour cela réaliser une comptabilité séparée.
        La liste de ces professionnels est détaillée à l'article II - 1.3.1.2. (page 31) de la circulaire du 11 avril 2019, que vous pourrez <a href='https://nova.entreprises.gouv.fr/nova/demande/creation/1177100/reglementation-sap/_get-circulaire-1' rel='nofollow'>télécharger ici</a>. 
        <br/>Pour les activités ne demandant pas d'agrément supplémentaire, on pourra noter les associations intermédiaires, les régies de quartiers, les communes, CCAS et CCIAS et les organismes gestionnaires médico-social.
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        5. Renseignements
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Pour cette étape vous pourrez d'abord renseigner le Siren de votre structure (9 chiffres), plusieurs champs seront alors complétés automatiquement.
        <br/>Si cette dernière étape produit des informations inccorectes, vous pourrez vérifier les différentes informations grâce au site <a href='https://www.pappers.fr/' target='_blank' rel='nofollow'>Pappers.fr</a> 
        <br/>
        <br/>Le NIC correspond aux 5 derniers chiffres de votre SIRET. Vous pourrez indiquer comme date d'ouverture le 1er jour du mois suivant (les déclarations effectuées le 17 février pourront indiquer le 1 mars).
        <br/>
        <br/>Veillez à bien vérifier les champs téléphone et mail, en effet un instructeur vous contactera sur ces derniers.
        <br/>
        <br/>Dans le cas où vous n'êtes pas le dirigeant, il vous faudra alors cocher la case en bas à droite 'Le demandeur est différent du dirigeant'.
        <br/>
        <br/>Vous pouvez enfin cocher "Je m'engage à tenir à jour..." puis cliquer sur Suivant.
        <br/>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        6. Transmettre ma demande de déclaration
      </div>
      <img className={classes.image} src={transmettre} style={{width:'80vw', maxWidth:'800px', marginTop:'0px', marginBottom:'20px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Une fois les différents champs vérifiés, vous pourrez cliquer sur Transmettre ma demande.
        <br/>Vous retrouverez alors les coordonnées de votre instructeur sur votre tableau de bord.
        <br/>
        <br/>Votre demande est dès à présent en train d'être traitée !
      </div>
      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Votre demande validée, vous recevrez votre code SAP !
        <br/>Vous pourrez alors commencer votre activité de Service à la Personne sereinement, et vos clients bénéficieront des 50% de crédit d'impôt sur l'ensemble de vos prestations (sous les seuils légaux évidemment).
        <br/>
        <br/>En attendant la validation de votre demande, nous avons encore de nombreux articles pour vous ! Trouver ses premiers clients, quels logiciels choisir pour bien commencer, un comparatif d'assurances, de banques, ...
        </div>
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>

      
    </div>
    </div>
    </>)
}

export default Element
import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import clsx from 'clsx'
import axios from "axios"

import Component from '../Component/component'
import mongoWrite from '../Component/mongoWrite'


const useStyles = makeStyles({
    main:{
        margin:'100px 0px 100px 0px',
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',

        //border:'2px solid',
    },
    mainFollow:{
        width:'90vw',
        maxWidth:'800px',
        //border:'2px solid black'
    },

    title:{
        marginBottom:'70px',

        fontWeight:'bold',
        fontSize:'2em',
        textAlign:'center',
        color:Component.color.main1,
        //border:''
    },
    subTitle:{
        marginTop:'30px',
        marginBottom:'20px',

        fontSize:'1.4em',
        fontWeight:'bold',
        color:Component.color.main2,
        //border:'2px solid'
    },
    input:{
        borderRadius:'5px',
        padding:'5px',
    },
    inputMail:{
        minWidth:'300px',
    },
    inputText:{
        minHeight:'160px',
        //padding:'40px',
        width:'95%',
    },
    text:{
        color:'rgba(50,50,50)'
    },
    buttonBlock:{
        marginTop:'20px',

        display:'flex',
        flexDirection:'column',
        alignItems:'center',
    },
    buttonLock:{
        background:'rgba(200,200,200)',
    },
    response:{
        marginTop:'20px',
    }

})







function Element(){
    const classes = useStyles()

    async function check(){
        let response = document.getElementById('response')
        let button = document.getElementById('button')
        button.innerHTML = 'Envoie en cours...'
        //extract and clean mail
        let mail = (document.getElementById('mail').value)
        mail = mail.replaceAll(' ','')
        mail = mail.toLowerCase()
        mail = mail.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
        let mailRegex = new RegExp('[a-z0-9\-._]{1,}[@][a-z0-9\-._]{3,}[.][a-z0-9]{2,}')
        mailRegex = mailRegex.test(mail)
        if(!mailRegex){response.innerHTML = 'Veuillez indiquer une adresse mail valide.'}    
        //on réalise les test
        let text = document.getElementById('text').value
        let textRegex = new RegExp('[^]{10,}')
        textRegex = textRegex.test(text)
        if(!textRegex){response.innerHTML = 'Veuillez renseigner un message d\'au moins 10 caractères.'}
        //send
        let collection = (mailRegex && textRegex)? 'contact' : 'contactFailed'
        mongoWrite({request:'write', collection:collection, body:{mail:mail, text:text}})
        if(mailRegex && textRegex){
            button.classList.add(classes.buttonLock)
            button.innerHTML = 'Message envoyé'
            response.innerHTML = 'Merci ! <br/>Notre équipe revient rapidement vers vous par mail.<br/>Vous allez être redirigé vers la page d\'accueil d\'ici quelques instants...'
        }
        else{
            button.innerHTML = 'Envoyer'
        }
        //await new Promise(resolve => setTimeout(resolve, 5000))
        //window.location.href='/'
    }
    
    return(
        <div className={classes.main}>
            <div className={classes.mainFollow}>
            
                <h1 className={classes.title}>
                    Nous contacter
                </h1>

                <div className={classes.subTitle}>
                    Votre adresse mail *
                </div>
                <input type='email' className={clsx(classes.input, classes.inputMail)} id='mail' placeholder='votre adresse mail'>
                </input>

                <div className={classes.subTitle}>
                    Votre message *
                </div>
                <textarea className={clsx(classes.input, classes.inputText)} id='text' placeholder='Bonjour, Je souhaiterais être recontacté par vos équipes.'>

                </textarea>

                <div className={classes.buttonBlock}>
                    <div className={clsx(Component.style().button, classes.buttonCliquable)} style={{width:'50vw', maxWidth:'200px', marginLeft:'0%'}} onClick={check} id='button'>
                        Envoyer
                    </div>
                    <div className={classes.response} id='response'></div>
                </div>

            </div>
        </div>
    )
}

export default Element
import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'
import Detailed from './detailed'

import imageSAP from './conclusion.jpg'
import choixRapide from './choixRapide.jpg'
import arbre from './arbre4.jpg'
import comparatif from './comparatif.jpg'



function Element(){
  const classes = ComponentArticle.useStyles()
  
    return(<>
    <Helmet>
      <title>Choisir sa forme jurique - Le Guide Complet pour Service à la Personne</title>
      <meta name="description" content="Comment choisir la forme juridique de son organisme de service à la personne ? Découvrez notre Guide complet."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Choisir sa forme juridique
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 20 septembre 2024
      </div>
      <img className={classes.image} src={choixRapide} style={{width:'80vw', maxWidth:'450px', marginTop:'30px',}} alt='Tableau de formes juridiques de son entreprise'/>
      <div className={classes.imageDescription}>Le choix de sa forme juridique dépend principalement du nombre d'associés et du type de rémunération des dirigeants</div>
      

      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='personneFragile'>
        Sommaire
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span><a href='#1'>1. Présentation détaillée des formes juriques</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#2'>2. Comparatif des différentes formes</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#3'>3. Rémunération du dirigeant</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#4'>4. Responsabilités</a>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Lorsque nous souhaitons lancer notre activité, une des premières décisions est le choix de sa forme juridique.
        <br/>
        <br/>Mais il n'est pas toujours facile de s'y retrouver entre les différentes formes possibles : micro-entreprise, entreprise individuelle, responsabilité limitée, société par actions simplifiée, ...
        <br/>C'est pourquoi nous allons tenter d'exposer clairement leurs principales différences, ainsi que les critères qui doivent être pris en compte dans votre choix.
        <br/>
        <br/>Le menu suivant, à travers ses questions simples, vous permettra de rapidement connaitre la structure la <strong>plus adaptée à votre projet</strong> :
        <br/>
      </div>
      <img className={classes.image} src={arbre} style={{width:'80vw', maxWidth:'700px',}} alt='arbre décisionnel choix forme juridique'/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'700px',}}>Un arbre décisionnel pour le choix de sa forme juridique</div>
      
      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='1'>
          1. Présentation détaillée des différentes formes possibles
      </div>
      <Detailed/>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='2'>
        2. Comparatif des formes juridiques
      </div>
      <img className={classes.image} src={comparatif} style={{width:'80vw', maxWidth:'700px', borderRadius:'20px',}}/>
      


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='3'>
        3. Rémunération du dirigeant : micro-social, TNS ou Assimilé-salarié ? 
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La fiscalité appliquée à la rémunération du dirigeant est sensiblement différente en fonction du choix de la forme juridique de votre entreprise :
        <br/>
        <br/><strong style={{color:ComponentArticle.color.column1Dark}}>Micro-social (micro-entreprise) : 21,98%</strong> 
        <br/>L'entrepreneur sera imposé directement sur son chiffre d'affaires à hauteur de 21,98% (21,2% de charges sociales, auxquelles viennent s'ajouter 0,2% de contribution à formation professionnelle et 0,044% de frais consulaires).
        <br/>
        <br/>Cette formule peut sembler la plus intéressante, mais elle ne permet pas de réaliser un chiffre d'affaires supérieur à 77 700€ (ou 188 700€ si vente de marchandise), et permet de bénéficier uniquement de protections faibles contrairement aux autres types de rémunération.
        <br/>L'auto-entrepreneur peut remplacer son impôt sur les revenus par le versement fiscal libératoire à un taux de 1,7% (pour les SAP).
        <br/>
        <br/><strong style={{color:ComponentArticle.color.column1Dark}}>Travailleur Non Salarié (TNS) : 45%</strong> 
        <br/>La rémunération de l'entrepreneur sera dans ce cas imposée à 45% (c'est à dire pour 100€ sur le compte du dirigeant, l'entreprise devra débourser 145€).
        <br/>Cette fiscalité reste avantageuse par rapport à une rémunération dite "Assimilé salarié", mais profite d'une protection sociale minimale, d'une pension retraite diminuée, et d'aucune prise en charge en cas d'accident du travail.
        <br/>
        <br/><strong style={{color:ComponentArticle.color.column1Dark}}>Assimilé salarié : 85%</strong> 
        <br/>L'assimilé salarié connait, il est vrai, la fiscalité la plus importante.
        <br/>Mais elle permet en retour de bénéficier des mêmes protections qu'un salarié "ordinaire" : sécurité sociale, retraite, ...
        <br/>La fiscalité sur la rémunération est ici de l'ordre de 85% (pour 100€ perçus, l'organisme devra débourser 185€). 
      </div>



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='4'>
        4. Responsabilités du dirigeant
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La <a href='https://www.vie-publique.fr/loi/281686-loi-du-14-fevrier-2022-activite-professionnelle-independante' rel='nofollow' target='_blank'>loi du 14 février 2022</a>, améliore grandement la protection des patrimoines des dirigeants d'Entreprises Individuelles, réduisant sensiblement leurs différences avec leur cousine EURL.
        <br/>
        <br/> En effet en cas de difficultés financières, les dirigeants de EI (et donc les micro-entreprises) voient leur patrimoine protégé par défaut. Seuls les actifs nécessaires à l'activité pourront être saisis.
        <br/> Les dirigeants de EURL, SARL, SASU et SAS ne risquent que la perte de leurs apports initiaux (capitaux et apports en nature).
        <br/>
        <br/>En cas de faute de gestion, les dirigeants encourent des risques quel que soit la forme juridique de leur structure.
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <img className={classes.image} src={imageSAP} style={{width:'60vw', maxWidth:'500px',}}/>
      <div className={classes.text}><span style={{color:'white'}}> .......... </span>
        Nombre d'associés, responsabilité, rémunération, ... 
        <br/>Nous comprenons maintenant mieux les principales différences entre les formes juridiques.
        <br/>Il s'agit d'une question cruciale dont dépendra votre rémunération mais aussi votre potentielle croissance.
        <br/>
        <br/>
        En espérant que cet article ai pu vous éclairer sur cette question complexe !
        <br/> Nous allons maintenant pouvoir passer au niveau supérieur : Transformer votre structure en <a href='/articleNova'>Organisme de Service à la Personne</a> !
        <br/>
        <br/>
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>


    </div>
    </div>



    </>)
}

export default Element
import { makeStyles } from '@mui/styles'

import logoFacebook from '../Component/facebook.png'
import logoYoutube from '../Component/youtube.png'
import logoLinkedin from '../Component/linkedin.png'
import logoInstagram from '../Component/instagram.png'


const useStyles = makeStyles({
    /*'@keyframes footerBackground':{
        '0%': {backgroundColor:'#264f71'},
        '50%': {backgroundColor:'#1e9afe'},
        '100%': {backgroundColor:'#264f71'},
      },*/
    main:{
        //marginTop:'100px',
        width:'100%',
        height:'300px',
        //background:'rgba(50,100,140)',
        background:'linear-gradient(145deg, rgba(40,80,100), 20%, rgba(50,100,140), 80%, #00AABE)', 
        opacity:'1',
        //background:'radial-gradient(circle at 0.1% 99.8%, rgb(0, 164, 189) 0%, rgb(0, 189, 165) 90%), linear-gradient( 180deg,#264f71, 50%, transparent)',
        //animation:'$footerBackground 10s infinite',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'center',
        color:'white',
        fontFamily: "'Open Sans', sans-serif",

        //border:'5px solid black',
    },
    title:{
        marginTop:'70px',
        marginBottom:'20px',
        fontSize:'1.3em',
        fontWeight:'bold',
    },
    text:{
        marginTop:"10px",
        textDecoration:'none',
        color:'white',
    },
    network:{
        marginTop:'30px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        //border:'2px solid black'
    },
    networkLogo:{
        height:'40px',
        margin:'0px 20px 0px 20px',
        opacity:'0.5',
        transition:'0.7s',
        "&:hover": {
            opacity: "0.7",
        },
    }
})

function Footer() {
    const classes = useStyles()
    return(
    <div className={classes.main}>
        <div className={classes.title}>Avance Immediate Services SAS</div>
        <a className={classes.text} href='https://avance-immediate.fr/MentionsLegales'>Mentions Légales</a>
        <a className={classes.text} href='https://avance-immediate.fr/contact'>Nous contacter</a>
        <div className={classes.network}>
            {false && <a href='https://www.facebook.com/AvanceImmediateServices' target='_blank' rel='nofollow'><img src={logoFacebook} className={classes.networkLogo} alt='facebook'/></a>}
            {false && <a href='https://www.instagram.com/avanceimmediateservices/' target='_blank' rel='nofollow'><img src={logoInstagram} className={classes.networkLogo} alt='instagram'/></a>}
            <a href='https://www.youtube.com/@avanceimmediate' target='_blank' rel='nofollow'><img src={logoYoutube} className={classes.networkLogo} alt='youtube'/></a>
        </div>
    </div>
    )
}

export default Footer
import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import clsx from 'clsx'
import axios from "axios"

import Component from './component'
import mongoWrite from '../Component/mongoWrite'
import cookieComponent from './cookieComponent'

const useStyles = makeStyles({
    main:{
        margin:'-50px 0px 100px 0px',
        paddingTop:'130px',
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',

        //border:'2px solid',
    },
    mainFollow:{
        width:'90vw',
        maxWidth:'800px',
        //border:'2px solid black'
    },

    title:{
        marginBottom:'70px',

        fontWeight:'bold',
        fontSize:'2em',
        textAlign:'center',
        color:Component.color.main1,
        //border:''
    },
    subTitle:{
        marginTop:'30px',
        marginBottom:'20px',

        fontSize:'1.4em',
        fontWeight:'bold',
        color:Component.color.main2,
        //border:'2px solid'
    },
    input:{
        borderRadius:'5px',
        padding:'5px',
    },
    inputMail:{
        minWidth:'300px',
    },
    inputText:{
        minHeight:'160px',
        //padding:'40px',
        width:'95%',
    },
    text:{
        color:'rgba(50,50,50)'
    },
    buttonBlock:{
        marginTop:'20px',

        display:'flex',
        flexDirection:'column',
        alignItems:'center',
    },
    buttonLock:{
        background:'rgba(200,200,200)',
    },
    response:{
        marginTop:'20px',
        color:Component.color.main2,
    }

})







function Element(props){
    const classes = useStyles()

    useLayoutEffect(() => {  
        let observer = new IntersectionObserver((entries, observer)=>{
          if(entries[0].isIntersecting){cookieComponent('complete')}
        }, {})
        observer.observe(document.getElementById('main'))
    })
    
    async function check(){
        //on importe les objets
        let mail = (document.getElementById('commentaryMail').value).toLowerCase()
        let text = document.getElementById('commentaryText').value
        let response = document.getElementById('commentaryResponse')
        let button = document.getElementById('commentaryButton')
        button.innerHTML = 'Envoie en cours...'
        //on check le mail
        mail = mail.replaceAll(' ','')
        mail = mail.toLowerCase()
        mail = mail.normalize("NFD").replace(/\p{Diacritic}/gu, "") //enlève les accents
        let mailRegex = new RegExp('[a-z0-9\-._]{1,}[@][a-z0-9\-._]{1,}[.][a-z0-9]{1,}')
        mailRegex = mailRegex.test(mail)
        //on check le text
        let textRegex = new RegExp('[^]{10,}')
        textRegex = textRegex.test(text)
        //on réalise les test
        if(!mailRegex || !textRegex){
            mongoWrite({request:'write', collection:'commentaryFail', body:{mail:mail, text:text}})
            if(!mailRegex){response.innerHTML = 'Veuillez indiquer une adresse mail valide.'}
            if(!textRegex){response.innerHTML = 'Veuillez renseigner un message d\'au moins 10 caractères.'}
            button.innerHTML = 'Envoyer'
            return ''
        }
        //if ok
        mongoWrite({request:'write', collection:'commentary', body:{mail:mail, text:text}})
        button.style.background=''
        button.classList.add(classes.buttonLock)
        button.innerHTML = 'Message envoyé'
        response.innerHTML = 'Merci ! <br/>Notre équipe revient rapidement vers vous par mail.<br/>Vous allez être redirigé vers la page d\'accueil d\'ici quelques instants...'
        //await new Promise(resolve => setTimeout(resolve, 5000))
        //window.location.href='/'
        
        return ''
    }
    
    return(
        <div className={classes.main} id='main'>
            <div className={classes.mainFollow}>
                <div className={classes.subTitle}>Laisser un commentaire</div>
                <input type='email' className={clsx(classes.input, classes.inputMail)} id='commentaryMail' placeholder='Votre mail'/>
                <textarea className={clsx(classes.input, classes.inputText)} id='commentaryText' placeholder='Votre message ici'></textarea>
                
                <div className={classes.buttonBlock}>
                    <div className={clsx(Component.style().button, classes.buttonCliquable)} style={{width:'50vw', maxWidth:'200px', marginLeft:'0%', background:Component.color.main2}} onClick={check} id='commentaryButton'>
                        Envoyer
                    </div>
                    <div className={classes.response} id='commentaryResponse'></div>
                </div>

            </div>
        </div>
    )
}

export default Element
import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'

import intro from './abstract.jpg'
import ais from './ais.png'
import calendly from './calendly.png'
import trello from './trello.png'
import tese from './tese.png'

function Element(){
  const classes = ComponentArticle.useStyles()

  let marginChapter='90px'
 
    return(<>
    <Helmet>
      <title>Logiciels Service à la Personne</title>
      <meta name="description" content="Les outils indispensables pour votre activité professionnelles. Nos équipes ont testés les meilleurs logiciels du marché afin de vous aider à développer votre activité."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Les Logiciels indispensables
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 1 octobre 2024
      </div>
      

      <img className={classes.image} src={intro} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'-20px', borderRadius:'20px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Dans le cadre de nos activités de Service à la Personne, nous manquons de temps pour la gestion de notre entreprise.
        <br/>
        <br/>Pour vous, notre équipe a testé de nombreux logiciels afin de vous permettre de gérer simplement votre activité, structurer votre planning et prises de rendez-vous, mieux travailler en équipe, réaliser vos fiches de paie, ...
        <br/>
        <br/>Et cerise sur le gâteau : l'ensemble des logiciels présentés proposent une version gratuite !
        <br/>C'est parti !
        <br/>
      </div>
      
      
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Au menu aujourd'hui :
      </div>
      <div className={classes.text} style={{fontWeight:'bold'}}>
        🎯
        <a href='#ais' style={{marginLeft:'10px',}}>
           Avance Immédiate Services : Solution de gestion dédiée au SAP
        </a>
        <br/>⌚️
        <a href='#calendly' style={{marginLeft:'10px',}}>
           Calendly : L'outil pour organiser votre planning
        </a>
        <br/>📊
        <a href='#trello' style={{marginLeft:'10px',}}>
        Trello : Travailler en équipe simplement
        </a>
        <br/>💎
        <a href='#tese' style={{marginLeft:'10px',}}>
           Tese : La plateforme pour créer vos fiches de paie
        </a>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:marginChapter}} id='ais'>
        Avance Immédiate Services : La solution de gestion dédiée au SAP
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Kesako ?</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        Avance Immédiate Services est une solution française (cocorico!), créée en 2021 et initialement dédiée à l'avance immédiate du crédit d'impôt (Habilitée par l'Urssaf et compatible à l'API Tiers de prestation, pour les connaisseurs ;) ).
        <br/>
        <br/>Aujourd'hui solution tout-en-un, elle permet aux professionnels du SAP de réaliser simplement leur facturation, attestations fiscales de fin d'année, le suivi de leur activité et permet évidemment de proposer l'Avance Immédiate à vos clients !
        <br/>
        <br/>Logiciel ergonomique en ligne, vous pouvez y accéder sur pc, mac, tablette et smartphone, afin de toujours pouvoir gérer votre activité, que vous soyez au bureau ou sur le terrain.
      </div>
      <img className={classes.image} src={ais} style={{width:'80vw', maxWidth:'800px', marginTop:'20px',borderRadius:'20px',boxShadow:'2px 2px 8px 3px rgba(200,200,200)',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'800px'}}>Avance Immédiate Services : Votre tableau de bord</div>
      <div className={classes.text}>
        <br/>En plus d'être une référence en avance immédiate, de nombreuses fonctionnalités vous permettent de simplifier le nouveau crédit d'impôt (auto-déclaration, xl easy, suivi).
        <br/>
        <br/>On apprécie également le support client joignable par mail, téléphone ou visio, afin d'être accompagné dans l'ensemble de nos démarches (habilitation à l'API Tiers de prestation notamment), et qui connait sur le bout des doigts le dispositif.
        <br/>
        <br/>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Une version gratuite</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        La majorité des fonctionnalités du logiciel peuvent être utilisées gratuitement. 
        <br/>La version gratuite permet d'accéder à une solution complète : clients et factures en illimités, attestations fiscales, suivi d'activité, ainsi que 15 factures en avance immédiate. 
        <br/>
        <br/>Les versions supérieures permettent un nombre illimité de factures en avance immédiate, d'accéder aux support client ainsi que de nombreuses fonctionnalités complémentaires.
        <br/>
        <br/>
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/5ZsAT0PVtFI'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <a className={Component.style().button} style={{marginTop:'30px'}} href='https://avance-immediate.fr/redirection/formation' target='_blank' rel='nofollow'>Découvrir Avance Immédiate Services</a>
      



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:marginChapter}} id='calendly'>
        Calendly : L'outil parfait pour organiser votre planning
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Kezako ?</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        Calendly est une startup américaine créée en 2013. 
        <br/>Leur plateforme liée à votre Google Agenda, vous permet de déterminer vos disponibilités, la durée de vos rendez-vous, le temps entre chaque prestations, ...
        <br/>
        <br/>Vos clients sélectionnent simplement le créneau qu'ils désirent pour une prestation ou un premier échange (comme cela peut être réalisée sur Doctolib).
      </div>
      <img className={classes.image} src={calendly} style={{width:'80vw', maxWidth:'700px', marginTop:'20px',borderRadius:'20px',boxShadow:'2px 2px 8px 3px rgba(200,200,200)',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'700px'}}>Calendly : Prise de rendez-vous coté client</div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Une version gratuite</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        La majorité des fonctionnalités du logiciel peuvent être utilisées gratuitement. 
        <br/>Les versions supérieures permettent de rajouter les relances par sms, créer plusieurs types d'évènements, avoir accès à un support client, permettre son intégration au sein d'autres solutions, ...
        <br/>
        <br/>
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/_8-0aA6UMTI'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <a className={Component.style().button} style={{marginTop:'30px'}} href='https://calendly.com/' target='_blank' rel='nofollow'>Découvrir Calendly</a>
      


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:marginChapter}} id='trello'>
        Trello: Travailler en équipe n'a jamais été aussi facile !
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Kezako ?</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        Trello est une solution de l'australien Atlassian lancée en 2011.
        <br/>Dédiée à la gestion de projet, elle permet à votre équipe de travailler sur des tableaux partagés (sous forme de calendrier, liste ou tableur "Excel").
        <br/>Votre équipe pourra ainsi créer des tâches à réaliser, les attribuer à un membre de votre équipe, indiquer lorsqu'elles sont effectuées, leur état de priorité, ...
        <br/>Chaque tâche peut être profondément détaillée, avec des images, des pièces jointes, des dates, ...
        <br/>
      </div>
      <img className={classes.image} src={trello} style={{width:'80vw', maxWidth:'700px', marginTop:'20px',borderRadius:'20px',boxShadow:'2px 2px 8px 3px rgba(200,200,200)',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'700px'}}>Trello : Les tâches à réaliser.</div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Une version gratuite</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        La majorité des fonctionnalités du logiciel peuvent être utilisées gratuitement. 
        <br/>Les versions supérieures permettent un nombre illimité de tableaux, un espace de stockage supplémentaire, de nouveaux type de tableau, et une gestion optimisée des droits administrateurs.
        <br/>
        <br/>
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/AyfupeWS0yY'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <a className={Component.style().button} style={{marginTop:'30px'}} href='https://trello.com/' target='_blank' rel='nofollow'>Découvrir Trello</a>
      




      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:marginChapter}} id='tese'>
        Tese: L'outil officiel pour faire des fiches de paie, gratuites et conforme ! 
      </div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Kezako ?</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        Tese est un outil développé par l'Urssaf, afin de permettre aux TPE de gérer l'ensemble de leurs salariés (CDI, CDD, alternant ou stage).
        Effectivement le calcul des cotisations sociales est une opération complexe qui était souvent laissée aux cabinets spécialisés ou logiciels de paies.
        <br/>
        <br/>le Titre Emploi Service Entreprise (ou "TESE", pouvant nous rappeller nos chers CESU), est une plateforme 100% en ligne et totalement gratuit.
        <br/>Vous pourrez notamment y réaliser vos déclarations préalables à l'embauche (DPAE), générer vos fiches de paie avec le calcul automatique des différentes cotisations.
      </div>
      <img className={classes.image} src={tese} style={{width:'80vw', maxWidth:'700px', marginTop:'20px',borderRadius:'20px',boxShadow:'2px 2px 8px 3px rgba(200,200,200)',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.imageDescription} style={{width:'80vw', maxWidth:'700px'}}>Tese : Créer vos fiches de paie simplement.</div>
      <div className={classes.text}>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Totalement gratuit mais avec certaines limites</span>
        <br/>
        <br/><span style={{color:'white'}}> .......... </span>
        Certains types de contrats ne sont pas gérés par le TESE : salariés avec cotisations réduites (artistes, journalistes, médecins, ...) et les stagiaires dont la rémunération est supérieure au minimum légal.
        <br/>
        <br/>
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/_6MrPK4RttE'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <a className={Component.style().button} style={{marginTop:'30px'}} href='https://www.letese.urssaf.fr/portail/accueil.html' target='_blank' rel='nofollow'>Découvrir Tese</a>
      



      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark, marginTop:marginChapter}}>
        Conclusion
      </div>
      <div className={classes.text}>
        <br/><span style={{color:'white'}}> .......... </span>
        Gagner en productivité demande de prendre le temps de choisir les bons outils qui vont nous accompagner durant plusieurs années. 
        <br/>
        <br/>La liste des solutions ci-dessus représente le travail de recherche et de comparaison réalisé par notre équipe.
        Nous avons voulu une liste la plus épurée que possible afin de vous proposer un kit complet et cohérent.
        <br/>
        <br/>Si vous avez la moindre recommandation, ou si vous utilisez un autre outil qui vous permet de gagner du temps, n'hésitez pas à nous en faire part en commentaire !
        <br/>
        <br/>En espérant que cet article ait pu vous permettre de découvrir de nouvelles solutions qui vous seront utiles,
        <br/>A très vite !
      </div>

      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>
      
      
    </div>
    </div>
    </>)
}

export default Element
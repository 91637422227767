import { useState, useEffect, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    main:{
        width:'60vw',
        margin:'100px 20vw 100px 20vw',
        
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        //alignItems:'end',

        //border:'2px solid',
    },

    title:{
        width:'100%',
        marginBottom:'50px',

        fontWeight:'bold',
        fontSize:'2em',
        textAlign:'center',
        color:'orange',
    },
    subTitle:{
        marginTop:'50px',
        marginBottom:'20px',
        fontSize:'1.4em',
        fontWeight:'bold',
        color:'orange',
    },
    text:{
        color:'rgba(50,50,50)'
    },

    button:{
        width:'150px',
        padding:'5px',
        marginTop:'50px',
        cursor:'pointer',
        textDecoration:'none',

        textAlign:'center',
        color:'white',
        fontWeight:'bold',
        background:'orange',
        borderRadius:'20px',
    },


})


function Component(){
    const classes = useStyles()
    
    return(
        <div className={classes.main}>
            
            <h1 className={classes.title}>
                Mentions Légales du site Servicealapersonne.fr
            </h1>

            <div className={classes.subTitle}>
                Servicealapersonne.fr
            </div>
            <div className={classes.text}>
                Propriété de Avance Immédiate Services
                <br/>Société par actions simplifiée au capital de 3.000 euros
                <br/>Immatriculée au Registre du Commerce et des Sociétés sous le numéro 912 324 076
                <br/>Ayant son siège au 31 rue du troupeau 95100 Argenteuil
                <br/>
                <br/>Contact : <a href='mailto:mentionlegale@servicealapersonne.fr'>mentionlegale@servicealapersonne.fr</a>
                <br/>Directeur de publication : M. Espalivet
            </div>

            <div className={classes.subTitle}>
                Informations sur l'hébergeur du site
            </div>
            <div className={classes.text}>
                Site hébergé sur les serveurs de la société Hostinger International
                <br/>L'hébergeur ayant son siège au 61 Lordou Vironos, Larnaca, CY
                <br/><a href='mailto:hostinger@hostingermail.com'>hostinger@hostingermail.com</a>
            </div>


            <a className={classes.button} href='/'>
                Page d'accueil
            </a>


        </div>
    )
}

export default Component